import { Provider, Viewport } from '@radix-ui/react-toast';

import Toast from './toast';
import { useToast } from './toast.hook';

export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const { toastList } = useToast();

  return (
    <Provider swipeDirection="down">
      {children}

      {toastList.map((toast) => (
        <Toast key={toast.id} {...toast} />
      ))}

      <Viewport className="fixed bottom-0 left-[50%] z-50 m-auto flex w-96 max-w-[50vw] translate-x-[-50%] list-none flex-col-reverse gap-3 pb-4 outline-none" />
    </Provider>
  );
};

export default ToastProvider;
