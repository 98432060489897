import { z } from 'zod';

export const URL_PATTERN =
  /^(https?:\/\/)([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

const productServiceSchema = z.object({
  benefits: z.array(
    z.string().min(1, 'Please enter a benefit or delete this field')
  ),
  features: z.array(
    z.string().min(1, 'Please enter a feature or delete this field')
  ),
  name: z.string().min(1, 'Name is required'),
  uniqueSellingPoints: z.array(
    z
      .string()
      .min(1, 'Please enter a unique selling point or delete this field')
  ),
});

export const updateCompanyInfoSchema = z.object({
  id: z.string().uuid(),
  category: z.string().min(1, 'Category is required'),
  description: z.string().min(1, 'Description is required'),
  productServices: z.array(productServiceSchema),
  name: z.string().min(1, 'Name is required'),
  website: z
    .string()
    .transform((value) =>
      value.startsWith('http') ? value : ['https://', value].join('')
    )
    .refine((transformedValue) => URL_PATTERN.test(transformedValue), {
      message: 'Invalid url. Please enter a valid url.',
    }),
});

export type UpdateCompanyInfoFormValues = z.infer<
  typeof updateCompanyInfoSchema
>;
