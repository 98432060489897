import { QuestionTemplate } from '@/manager-graphql';
import { cn } from '@/shared-ui';
import { TAG_COLORS } from '@/utilities';
import { useFormContext } from 'react-hook-form';
import { FormData } from './manager-onboarding-questions.types';

interface ButtonGroupProps {
  options: QuestionTemplate[];
  fieldName: 'selectedQuestionId';
}
export const RenderButtonGroup = ({ options, fieldName }: ButtonGroupProps) => {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<FormData>();
  const fieldValue = watch(fieldName);
  const hasError = !!errors[fieldName]?.message && !fieldValue;

  return (
    <div className="flex flex-col gap-2">
      <div className="inline-flex flex-wrap items-start justify-start gap-3">
        {options.map((option, idx) => renderButton(option, idx))}
      </div>
      {hasError && (
        <div className="text-sm text-red-600">{errors[fieldName]?.message}</div>
      )}
    </div>
  );

  function renderButton(option: QuestionTemplate, index: number) {
    const tagColorIndex = (index + 1) % TAG_COLORS.length;
    const { darkColor, lightColor } = TAG_COLORS[tagColorIndex];

    return (
      <button
        key={option.primaryQuestion}
        type="button"
        onClick={() => setValue(fieldName, option.primaryQuestion)}
        data-state={
          hasError
            ? 'error'
            : fieldValue === option.primaryQuestion
            ? 'selected'
            : 'default'
        }
        className={cn(
          'inline-flex w-full items-center justify-between gap-2 rounded-lg border p-3 text-base font-normal leading-normal tracking-tight text-gray-800 transition-all duration-200 ease-in-out',
          'data-[state=error]:border-red-500 data-[state=selected]:border-gray-800 data-[state=default]:bg-gradient-to-r data-[state=default]:from-[#FFF1F2] data-[state=default]:to-[#EEF2FF]'
        )}
      >
        <span className="mr-2 flex-grow text-left">
          {option.primaryQuestion}
        </span>
        <span
          className={cn(
            'ml-2 flex-shrink-0 whitespace-nowrap rounded px-2 py-1 text-sm font-normal leading-5',
            `${lightColor} ${darkColor}`
          )}
        >
          {option.tagName}
        </span>
      </button>
    );
  }
};
