import {
  ProjectChat,
  ProjectPoweredBy,
  ProjectResponseProgressBar,
} from '@/project-response';
import { ComponentProps } from 'react';
import ManagerBrandingPreviewSection from './manager-branding-preview-section';
import {
  DEFAULT_THEYSAID_LOGO_URL,
  SAMPLE_MESSAGES,
  SAMPLE_PROGRESS,
} from './manager-branding.helpers';

export default function ManagerBrandingMobilePreview({
  accentColor,
  logoUrl,
  avatarUrl,
  showPoweredBy,
}: ComponentProps<typeof ManagerBrandingPreviewSection>) {
  return (
    <div className="animate-fade-in flex h-[40rem] flex-col items-center justify-center gap-0 rounded-xl bg-gray-100 p-6">
      <div
        className="relative mx-auto flex min-h-[58rem] w-full max-w-[27rem] origin-center scale-[0.6] flex-col items-center self-stretch overflow-hidden overflow-y-auto rounded-3xl border-4 border-zinc-300 bg-gray-100"
        style={{
          background: accentColor
            ? `linear-gradient(to bottom left, white 40%, ${accentColor})`
            : 'linear-gradient(to bottom left, white 45%, #ffe8ec 55%, #e8edff)',
        }}
      >
        <img
          src="/img/mobile-browser-header.svg"
          alt="Desktop browser header"
          className="self-stretch"
        />
        <div className="flex w-full items-center gap-2 bg-white p-4">
          <img
            src={logoUrl ?? DEFAULT_THEYSAID_LOGO_URL}
            alt="Logo"
            data-default={!logoUrl}
            className="max-h-8 w-full max-w-24 object-contain data-[default=true]:brightness-0"
          />
          <div className="w-full flex-1">
            <ProjectResponseProgressBar
              progress={SAMPLE_PROGRESS}
              accentColor={accentColor ?? undefined}
            />
          </div>
        </div>
        <ProjectChat
          accentColor={accentColor ?? undefined}
          avatarUrl={avatarUrl}
          className="self-stretch !rounded-t-none"
          messages={SAMPLE_MESSAGES}
          onSubmit={Promise.resolve}
          scrollToEnd={false}
          hideNewLineText
        />
        {showPoweredBy && (
          <div className="py-4">
            <ProjectPoweredBy />
          </div>
        )}
      </div>
    </div>
  );
}
