import { gql } from '../__generated__/gql';

export const GET_ORGANIZATION_SETTINGS = gql(/* GraphQL */ `
  query GetOrganizationSettings {
    orgSettings {
      id
      avatarUrl
      logoUrl
      brandColor
      hideTheysaidLogo
      skipSurveyWelcome
    }
  }
`);
