import { StateMessageLoading } from '@/shared-ui';
import { Navigate, useOutlet } from 'react-router-dom';
import { useHomeWrapper } from './home-wrapper.hooks';

export default function HomeWrapper() {
  const outlet = useOutlet();
  const {
    shouldRedirectToOnboard,
    companyOnboardingPath,
    shouldRedirectToHome,
    isLoading,
    homePath,
  } = useHomeWrapper();

  if (shouldRedirectToOnboard) {
    return <Navigate replace to={companyOnboardingPath} />;
  }

  if (shouldRedirectToHome) {
    return <Navigate replace to={homePath} />;
  }

  if (isLoading) {
    return (
      <div className="w-full bg-white">
        <main className="flex h-full items-center justify-center px-10 py-6">
          <div className="flex max-w-lg flex-col gap-11">
            <StateMessageLoading />
          </div>
        </main>
      </div>
    );
  }

  return outlet;
}
