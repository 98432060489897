import { gql } from '../__generated__/gql';

export const ADD_INSIGHT = gql(/* GraphQL */ `
  mutation AddInsight($input: AddInsightInput!) {
    addInsight(input: $input) {
      id
      messages {
        id
        content
        createdAt
        finished
        sender
      }
      primaryAnswer
    }
  }
`);
