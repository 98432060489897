import { gql } from '../__generated__/gql';

export const UPDATE_ORGANIZATION_SETTINGS = gql(/* GraphQL */ `
  mutation UpdateOrganizationSettings(
    $input: UpdateOrganizationSettingsInput!
  ) {
    updateOrgSettings(input: $input) {
      id
      avatarUrl
      logoUrl
      brandColor
      hideTheysaidLogo
    }
  }
`);
