export default function SurveyPoweredBy() {
  return (
    <div className="space-y-1.5">
      <div className="text-center">
        <span className="text-[0.5rem] font-normal leading-3 text-zinc-400">
          Powered by
        </span>

        <img
          alt="TheySaid logo"
          className="mx-auto h-5"
          src="https://ai.theysaid.io/survey/img/theysaid-logo-gray.svg"
        />
      </div>

      <div className="text-center text-[0.5rem] font-normal leading-3 text-stone-500">
        <p>
          Sign up{' '}
          <a
            href="https://ai.theysaid.io/sign-up"
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
          >
            here
          </a>{' '}
          to send your first survey.
        </p>

        <p>AI can make mistakes. Check important info.</p>
      </div>
    </div>
  );
}
