import { cn } from '@/shared-ui';
import { faArrowUp } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef } from 'react';
import { ProjectResponseSendComponentProps } from './project-response-renderer.type';

export default function ProjectResponseOpenEndSendComponent({
  register,
  onKeyDown,
  isSubmitting,
  value,
  accentColor,
  hideNewLineText,
}: ProjectResponseSendComponentProps) {
  const sendButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <div
      className={cn(
        'flex h-12 flex-1 items-center justify-center gap-2 rounded-lg sm:gap-1',
        value?.trim().length && 'sm:gap-2'
      )}
    >
      <textarea
        {...register?.('content')}
        placeholder="Respond to the AI"
        data-indication={!value?.trim().length}
        className="flex shrink grow basis-0 resize-none items-center justify-between self-stretch rounded-lg border border-gray-200 bg-white px-4 py-2.5 transition-all placeholder:font-light placeholder:text-gray-400 focus:border-gray-400 focus:outline-none data-[indication=true]:after:absolute data-[indication=true]:after:inset-0"
        onKeyDown={onKeyDown}
        disabled={isSubmitting}
        readOnly={isSubmitting}
      />
      {!hideNewLineText && !value?.trim().length ? (
        <div className="relative right-[8.5rem] hidden w-0 text-nowrap sm:block">
          <div className="text-right font-sans text-xs text-gray-400">
            new line: shift+return
          </div>
        </div>
      ) : null}

      <button
        disabled={isSubmitting || !value?.trim()}
        ref={sendButtonRef}
        className="inline-flex w-16 flex-col items-center justify-center gap-2 self-stretch rounded-lg bg-gradient-to-b from-gray-800 to-gray-900 px-3 py-2 transition-all disabled:opacity-20"
        style={{
          background: accentColor ? 'none' : undefined,
          backgroundColor: accentColor ?? undefined,
        }}
      >
        <FontAwesomeIcon icon={faArrowUp} className="h-6 w-6 text-white" />
      </button>
    </div>
  );
}
