import { gql } from '../__generated__/gql';

export const GENERATE_AI_QUESTION = gql(/* GraphQL */ `
  query GenerateAIQuestion($input: GenerateQuestionInput!) {
    generateAIQuestion(input: $input) {
      minRatingLabel
      maxRatingLabel
      options {
        label
        value
      }
      text
      tag
      type
    }
  }
`);
