import { gql } from '../__generated__/gql';

export const LIST_ONBOARDING_QUESTION_TEMPLATES = gql(/* GraphQL */ `
  query ListOnboardingQuestionTemplates {
    onboardingQuestionTemplates {
      primaryQuestion
      ratingLabels
      ratingMinValue
      tagName
    }
  }
`);
