import {
  faArrowLeftToLine,
  faArrowRightToLine,
  faCircleQuestion,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { UpgradeSidebar } from '@/manager-upgrade';
import AnchorLink from './manager-sidebar.anchor-link';
import SidebarFooterMenu from './manager-sidebar.footer-menu';
import { useSidebar } from './manager-sidebar.hooks';

interface SidebarProps {
  children: React.ReactNode;
}

export default function Sidebar({ children }: SidebarProps) {
  const { sidebarState, toggleSidebar } = useSidebar();

  return (
    <aside className="sticky top-0 h-screen shrink-0">
      <nav
        data-state={sidebarState}
        className="group/sidebar flex h-full w-[4.75rem] flex-col overflow-y-auto border-r bg-zinc-900 shadow-sm transition-all duration-200 hover:w-64 data-[state=fixed]:w-64"
      >
        <div
          data-state={sidebarState}
          className="flex h-28 justify-between px-2 py-6 transition-all group-hover/sidebar:px-4 data-[state=fixed]:px-4"
        >
          <div>
            <div className="flex">
              <img
                data-state={sidebarState}
                src="/img/theysaid-logo-white-horizontal.svg"
                className="hidden h-8 transition-all group-hover/sidebar:inline data-[state=fixed]:inline"
                alt="TheySaid Logo"
              />
              <img
                data-state={sidebarState}
                src="/img/theysaid-logo-white.svg"
                className="mx-auto h-10 transition-all group-hover/sidebar:hidden data-[state=fixed]:hidden"
                alt="TheySaid Logo"
              />
            </div>
            <img
              data-state={sidebarState}
              src="/img/2.0beta-logo.svg"
              className="mt-2 h-5 transition-all"
              alt="2.0 Beta Logo"
            />
          </div>

          <button
            data-state={sidebarState}
            className="hidden h-10 w-10 rounded-lg bg-zinc-700 p-1.5 text-white transition-colors hover:bg-zinc-100 hover:text-zinc-500 group-hover/sidebar:block"
            onClick={toggleSidebar}
          >
            <FontAwesomeIcon
              icon={
                sidebarState === 'fixed'
                  ? faArrowLeftToLine
                  : faArrowRightToLine
              }
              size="lg"
            />
          </button>
        </div>

        <ul className="flex-1 space-y-4 px-4">{children}</ul>

        <UpgradeSidebar sidebarState={sidebarState} />

        <div className="p-4">
          <AnchorLink
            children="Help Docs"
            icon={faCircleQuestion}
            href="https://doc.clickup.com/9006006330/p/h/8cctg1u-6931/8a72f7279ee6772"
            rel="noreferrer"
            target="_blank"
          />
        </div>

        <SidebarFooterMenu />
      </nav>
    </aside>
  );
}
