import { useAuth } from '@workos-inc/authkit-react';
import { useEffect } from 'react';

import Redirecting from './auth.redirecting';

export default function SignUpPage() {
  const { signUp } = useAuth();

  useEffect(() => {
    signUp({ state: { returnTo: 'home' } });
  }, [signUp]);

  return <Redirecting />;
}
