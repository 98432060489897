import {
  SwitchProps as ReactSwitchProps,
  Root,
  Thumb,
} from '@radix-ui/react-switch';
import { forwardRef } from 'react';

import { cn } from './ui.helpers';

interface SwitchProps extends ReactSwitchProps {
  thumbClassName?: string;
}

const Switch = forwardRef<HTMLButtonElement, SwitchProps>(
  ({ className, thumbClassName, ...props }, forwardedRef) => {
    return (
      <Root
        {...props}
        className={cn(
          'relative h-4 min-h-4 w-6 min-w-6 cursor-default rounded-full bg-zinc-200 outline-none transition-colors focus:border-0 enabled:hover:bg-gray-300 disabled:cursor-not-allowed data-[state=checked]:bg-gray-800 data-[state=checked]:enabled:hover:bg-gray-700',
          className
        )}
        ref={forwardedRef}
      >
        <Thumb
          className={cn(
            'block h-3 w-3 translate-x-0.5 rounded-full bg-white transition-transform duration-100 will-change-transform data-[state=checked]:translate-x-2.5',
            thumbClassName
          )}
        />
      </Root>
    );
  }
);

export default Switch;
