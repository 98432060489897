import { ColorInput, ImageUpload, Switch } from '@/shared-ui';
import { forwardRef } from 'react';
import { FIVE_MEGABYTES } from './manager-branding.helpers';
import {
  BrandingFormValues,
  useManagerBrandingCustomizeSection,
} from './manager-branding.hooks';

interface ManagerBrandingCustomizeSectionProps {
  accentColor: string | undefined | null;
  avatarUrl: string | null;
  logoUrl: string | null;
  showPoweredBy: boolean;
  onChange: (
    key: keyof BrandingFormValues,
    value: BrandingFormValues[typeof key]
  ) => void;
  onDeleteImage: (key: 'avatar' | 'logo') => void;
}

const ManagerBrandingCustomizeSection = forwardRef(
  (
    {
      accentColor,
      avatarUrl,
      logoUrl,
      showPoweredBy,
      onChange,
      onDeleteImage,
    }: ManagerBrandingCustomizeSectionProps,
    ref: React.Ref<{ reset: () => void }>
  ) => {
    const { avatarUploadRef, logoUploadRef } =
      useManagerBrandingCustomizeSection(ref);
    return (
      <div className="flex flex-col gap-4">
        <h1 className="text-lg font-bold text-gray-900">Customize</h1>
        <div className="flex flex-col gap-6 self-stretch">
          <ImageUpload
            allowedTypes={['image/svg+xml', 'image/jpeg', 'image/png']}
            maxAllowedResolution={{ width: 100, height: 100 }}
            value={avatarUrl}
            onChange={(file) => onChange('avatar', file)}
            onDelete={() => onDeleteImage('avatar')}
            label="Chatbot avatar"
            ref={avatarUploadRef}
            maxFileSize={FIVE_MEGABYTES}
          />
          <ImageUpload
            allowedTypes={['image/svg+xml', 'image/jpeg', 'image/png']}
            maxAllowedResolution={{ width: 600, height: 600 }}
            value={logoUrl}
            onChange={(file) => onChange('logo', file)}
            onDelete={() => onDeleteImage('logo')}
            label="Logo"
            ref={logoUploadRef}
            maxFileSize={FIVE_MEGABYTES}
          />
          <ColorInput
            label="Accent color"
            value={accentColor ?? ''}
            onChange={(color) => onChange('accentColor', color)}
          />
          <div className="flex items-center justify-between self-stretch">
            <label className="font-display text-sm font-medium leading-tight tracking-tight text-zinc-800">
              Powered By TheySaid
            </label>
            <Switch
              checked={showPoweredBy}
              onCheckedChange={(show) => onChange('poweredBy', show)}
              className="h-5 w-10 bg-neutral-300"
              thumbClassName="data-[state=checked]:translate-x-6 h-3.5 w-3.5"
            />
          </div>
        </div>
      </div>
    );
  }
);

export default ManagerBrandingCustomizeSection;
