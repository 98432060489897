import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import ProjectResponseSelectItem from './project-response-select-item';

export default function ProjectResponseRankingItem({
  value,
  label,
  tag,
  disabled,
  id,
}: Omit<
  React.ComponentProps<typeof ProjectResponseSelectItem>,
  'isDragAndDrop' | 'onClick'
> & { id: string }) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="flex w-full flex-1 touch-none"
    >
      <ProjectResponseSelectItem
        value={value}
        tag={tag}
        disabled={disabled}
        isDragAndDrop
        label={label}
      />
    </div>
  );
}
