import { useAuth } from '@workos-inc/authkit-react';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import Redirecting from './auth.redirecting';

export default function SignInPage() {
  const { signIn } = useAuth();
  const [params] = useSearchParams();
  const context = params.get('context') ?? undefined;

  useEffect(() => {
    signIn({ context, state: { returnTo: 'home' } });
  }, [context, signIn]);

  return <Redirecting />;
}
