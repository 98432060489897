import { cn } from './ui.helpers';

interface SkeletonProps {
  className?: string;
  width?: string | number;
  height?: string | number;
}

export const Skeleton = ({ className, width, height }: SkeletonProps) => {
  return (
    <div
      className={cn('animate-pulse rounded-md bg-gray-200', className)}
      style={{
        width: width,
        height: height,
      }}
    />
  );
};

export default Skeleton;
