import { gql } from '../__generated__/gql';

export const INVITE_USER = gql(/* GraphQL */ `
  mutation InviteUser($input: InviteInput!) {
    inviteUser(input: $input) {
      id
      email
    }
  }
`);
