import { faArrowUp, faSpinner } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProjectResponseSendComponentProps } from './project-response-renderer.type';

export default function ProjectResponseSelectSendButton({
  onSubmit,
  disabled,
  isSubmitting,
}: ProjectResponseSendComponentProps) {
  return (
    <button
      disabled={disabled}
      onClick={onSubmit}
      className="flex h-10 w-full flex-1 items-center justify-center gap-2 rounded-lg bg-gray-900 px-3 py-2 transition-all disabled:opacity-20"
    >
      {isSubmitting ? (
        <FontAwesomeIcon
          icon={faSpinner}
          className="relative h-6 w-6 animate-spin text-white"
        />
      ) : (
        <FontAwesomeIcon
          icon={faArrowUp}
          className="relative h-6 w-6 text-white"
        />
      )}
    </button>
  );
}
