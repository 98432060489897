import { gql } from '../__generated__/gql';

export const CREATE_SIGNED_UPLOAD_URL = gql(/* GraphQL */ `
  mutation CreateSignedUploadUrl($input: CreateSignedUrlInput!) {
    createSignedUploadUrl(input: $input) {
      url
      publicUrl
    }
  }
`);
