import { gql } from '../__generated__/gql';

export const REFRESH_COMPANY_INFO = gql(/* GraphQL */ `
  mutation RefreshCompanyInfo {
    refreshCompanyInfo {
      id
      name
      category
      description
      website
      productServices {
        id
        name
        benefits
        features
        uniqueSellingPoints
      }
    }
  }
`);
