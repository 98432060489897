import RatingScaleSelect from './project-response-rating-scale-select';
import { ProjectResponseOpenEndRendererComponent } from './project-response-renderer-open-end';
import { ProjectResponseComponentProps } from './project-response-renderer.type';

export function ProjectResponseRatingScaleRendererComponent({
  options = [],
  text,
  value,
  onChange,
  readonly,
}: ProjectResponseComponentProps) {
  return (
    <div className="flex flex-col gap-2">
      <ProjectResponseOpenEndRendererComponent text={text} />
      <RatingScaleSelect
        onClick={(value) => onChange?.(value)}
        options={options}
        value={value ?? ''}
        readonly={readonly}
      />
    </div>
  );
}
