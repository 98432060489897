import DateRangePickerCalendar from './ui.date-range-picker-calendar';
import DateRangePickerCalendarHeader from './ui.date-range-picker-calendar-header';
import DateRangePickerDateIndicator from './ui.date-range-picker-date-indicator';
import DateRangePickerIndicator from './ui.date-range-picker-indicator';
import DateRangePickerLeftPanel from './ui.date-range-picker-left-panel';
import { useDateRangePicker, useViewport } from './ui.date-range-picker.hooks';
import { cn } from './ui.helpers';

interface DateRangePickerProps {
  startDate: Date;
  endDate: Date;
  onChange: (startDate: Date, endDate: Date) => void;
  className?: string;
}

export default function DateRangePicker({
  startDate,
  endDate,
  onChange,
  className,
}: DateRangePickerProps): JSX.Element {
  const { width } = useViewport();

  const {
    open,
    toggle,
    pendingStartDate,
    pendingEndDate,
    calendarSelectedMonthOfYear,
    nextMonthFronCalendarSelectedMonthOfYear,
    showSecondCalendarThresholdWidth,
    dateRangeRef,
    handleChange,
    handleChangeFromLeftPanelOptions,
    handleNextMonthSelection,
    handlePreviousMonthSelection,
  } = useDateRangePicker({ startDate, endDate, onChange });

  return (
    <div className={cn(className, 'relative')} ref={dateRangeRef}>
      <button onClick={toggle}>
        <DateRangePickerIndicator startDate={startDate} endDate={endDate} />
      </button>
      {open && (
        <div className="absolute right-0 top-full z-10 flex flex-col-reverse rounded-md border-zinc-200 bg-white shadow sm:w-max sm:flex-row">
          <DateRangePickerLeftPanel
            startDate={startDate}
            endDate={endDate}
            onChange={handleChangeFromLeftPanelOptions}
          />
          <div className="flex justify-center gap-6 p-6">
            <div className="flex flex-col gap-6">
              <DateRangePickerCalendarHeader
                onNextMonthSelection={handleNextMonthSelection}
                onPreviousMonthSelection={handlePreviousMonthSelection}
                selectedMonthAndYear={calendarSelectedMonthOfYear}
                widthThreshold={showSecondCalendarThresholdWidth}
              />
              <div className="flex gap-6">
                <div>
                  <DateRangePickerCalendar
                    month={calendarSelectedMonthOfYear[0]}
                    year={calendarSelectedMonthOfYear[1]}
                    startDate={pendingStartDate}
                    endDate={pendingEndDate}
                    onChange={handleChange}
                  />
                </div>
                <div
                  data-hide={width < showSecondCalendarThresholdWidth}
                  className="data-[hide=true]:hidden"
                >
                  <DateRangePickerCalendar
                    month={nextMonthFronCalendarSelectedMonthOfYear[0]}
                    year={nextMonthFronCalendarSelectedMonthOfYear[1]}
                    startDate={pendingStartDate}
                    endDate={pendingEndDate}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div
                className="flex flex-wrap items-center gap-x-4 gap-y-2 self-stretch data-[small=true]:flex-col"
                data-small={width < showSecondCalendarThresholdWidth}
              >
                <DateRangePickerDateIndicator
                  date={pendingStartDate}
                  label="From"
                  placeholderForEmptyDate="Select a start date"
                />
                <DateRangePickerDateIndicator
                  date={pendingEndDate}
                  label="To"
                  placeholderForEmptyDate="Select an end date"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
