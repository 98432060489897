import { faLoader } from '@fortawesome/pro-light-svg-icons';

import { StateMessage } from '@/shared-ui';

export default function Loading() {
  return (
    <div className="fixed inset-0 flex h-full w-full items-center justify-center bg-white">
      <StateMessage
        heading="Loading..."
        icon={faLoader}
        iconClassName="animate-spin-slow"
      />
    </div>
  );
}
