import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import { v4 as uuid } from 'uuid';

import type { ToastItem } from './toast.types';

const INITIAL_STATE: ToastItem[] = [];

const toastListState = atom({
  key: 'toastList',
  default: INITIAL_STATE,
});

export const useToast = () => {
  const [toastList, setToastList] = useRecoilState(toastListState);

  const addToast = useCallback(
    (data: Omit<ToastItem, 'id'>) => {
      setToastList((prevState) => [...prevState, { ...data, id: uuid() }]);
    },
    [setToastList]
  );

  const removeToast = useCallback(
    (id: ToastItem['id']) => {
      setToastList((prevState) => prevState.filter((i) => i.id !== id));
    },
    [setToastList]
  );

  return { toastList, addToast, removeToast };
};
