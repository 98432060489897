import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { format } from 'date-fns';

interface DateRangePickerDateIndicatorProps {
  date: Date | null;
  label: string;
  placeholderForEmptyDate?: string;
}

export default function DateRangePickerDateIndicator({
  date,
  label,
  placeholderForEmptyDate = '',
}: DateRangePickerDateIndicatorProps): JSX.Element {
  return (
    <div className="flex flex-1 flex-col items-start text-start">
      <span className="text-xs text-gray-500">{label}</span>
      <div className="flex h-8 w-full min-w-72 items-center gap-2 rounded-md border border-zinc-200 px-3 py-1 text-sm shadow">
        <FontAwesomeIcon icon={faCalendar} className="mb-0.5 text-zinc-400" />
        <span className="text-sm font-normal leading-tight">
          {date
            ? format(date.toDateString(), 'dd/MM/yyyy')
            : placeholderForEmptyDate}
        </span>
      </div>
    </div>
  );
}
