import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Item, ItemIndicator, ItemText } from '@radix-ui/react-select';
import { forwardRef } from 'react';
import { cn } from './ui.helpers';

interface SelectItemProps extends React.ComponentPropsWithoutRef<typeof Item> {
  showIndicator?: boolean;
}

const SelectItem = forwardRef<React.ElementRef<typeof Item>, SelectItemProps>(
  ({ className, children, showIndicator, ...props }, ref) => (
    <Item
      ref={ref}
      className={cn(
        'relative flex w-full cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none focus:bg-gray-100 focus:text-gray-700 data-[disabled]:pointer-events-none data-[state=checked]:bg-gray-50 data-[disabled]:opacity-50 data-[state=checked]:focus:bg-gray-100',
        showIndicator && 'pl-8',
        className
      )}
      {...props}
    >
      {showIndicator ? (
        <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
          <ItemIndicator>
            <FontAwesomeIcon icon={faCheck} className="h-4 w-4" />
          </ItemIndicator>
        </span>
      ) : null}

      <ItemText>{children}</ItemText>
    </Item>
  )
);

export default SelectItem;
