import { gql } from '../__generated__/gql';

export const GET_USER_MANAGEMENT_DATA = gql(/* GraphQL */ `
  query GetUserManagementData(
    $after: Cursor
    $before: Cursor
    $first: Int
    $last: Int
  ) {
    invitations(after: $after, before: $before, first: $first, last: $last) {
      items {
        id
        email
      }
    }
    users(after: $after, before: $before, first: $first, last: $last) {
      items {
        id
        email
        firstName
        lastName
      }
    }
  }
`);
