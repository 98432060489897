import { gql } from '../__generated__/gql';

export const ADD_QUESTION_V2 = gql(/* GraphQL */ `
  mutation AddQuestionV2($input: CreateQuestionV2Input!) {
    createQuestionV2(input: $input) {
      id
      type
      text
      required
      aiInsights
      aiConvoDepth
      questionOrder
      multipleSelect
      randomize
      options {
        id
        label
        value
      }
      tags {
        id
        name
        color
      }
    }
  }
`);
