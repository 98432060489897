import * as Sentry from '@sentry/react';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';

import App from './app/app';

const isDev = window.location.host.includes('dev');
const isLocalhost = window.location.hostname === 'localhost';

Sentry.init({
  dsn: 'https://ac5cc3a8c3b7d890f6315e2b14cf6887@o358591.ingest.us.sentry.io/4507562919526400',
  environment: isDev ? 'dev' : 'prod',
  enabled: !isLocalhost,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: isDev ? 0.1 : 1.0,
  tracePropagationTargets: [/^https:\/\/.*.theysaid\.io\/graphql/],
  // Session Replay
  replaysSessionSampleRate: isDev ? 1.0 : 0.1,
  replaysOnErrorSampleRate: isDev ? 0.1 : 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
