import { gql } from '../__generated__/gql';

export const LIST_QUESTION_TEMPLATES = gql(/* GraphQL */ `
  query ListQuestionTemplates {
    questionTemplates {
      primaryQuestion
      ratingLabels
      ratingMinValue
      tagName
    }
  }
`);
