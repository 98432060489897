import {
  faClipboardListCheck,
  faHouseBlank,
  faSquarePollHorizontal,
} from '@fortawesome/pro-light-svg-icons';

import { useSidebarData } from './manager-sidebar.hooks';
import Sidebar from './manager-sidebar.sidebar';
import SidebarItem from './manager-sidebar.sidebar-item';

interface ManagerSidebarProps {
  children: React.ReactNode;
}

export default function ManagerSidebar({ children }: ManagerSidebarProps) {
  const { hasValidOrg, totalUnreadInsights } = useSidebarData();

  return (
    <div className="flex min-h-screen w-full">
      <Sidebar>
        {hasValidOrg ? (
          <>
            <SidebarItem to="/home" icon={faHouseBlank} text="Home" />
            <SidebarItem
              to="/projects"
              icon={faClipboardListCheck}
              text="Projects"
            />
            <SidebarItem
              to="/insights"
              icon={faSquarePollHorizontal}
              text="Insights"
              alert={totalUnreadInsights || null}
            />
          </>
        ) : null}
      </Sidebar>

      <main className="flex-1 overflow-x-hidden">
        {children}
      </main>
    </div>
  );
}
