import { gql } from '../__generated__/gql';

export const GET_API_DOCS = gql(/* GraphQL */ `
  query GetApiDocs {
    apiDoc {
      name
      pages {
        content
        id
        name
      }
    }
  }
`);
