import { isSameDay, startOfDay, format } from 'date-fns';
import React, { useMemo } from 'react';

import { daysInMonth, getStartDay } from './ui.date-range-picker.helpers';

interface DateRangePickerCalendarProps {
  month: number;
  year: number;
  startDate: Date | null;
  endDate: Date | null;
  onChange: (date: Date) => void;
}

const DAYS_OF_WEEK = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

export default function DateRangePickerCalendar({
  month,
  year,
  startDate,
  endDate,
  onChange,
}: DateRangePickerCalendarProps): JSX.Element {
  const calendarDaysGrid = useMemo(() => {
    const totalDaysInMonth = daysInMonth(month, year);
    let startDayOfWeek = getStartDay(month, year) - 1;
    startDayOfWeek = startDayOfWeek < 0 ? 6 : startDayOfWeek;

    const grid = Array(6 * 7).fill(null);
    for (let day = 1; day <= totalDaysInMonth; day++) {
      grid[startDayOfWeek + day - 1] = day;
    }
    return grid;
  }, [month, year]);

  const renderDay = (day: number | null, index: number) => {
    if (!day) return <div key={`empty-${month}-${year}-${index}`} />;

    const currentDate = new Date(year, month, day);
    const isSelected =
      (startDate && isSameDay(currentDate, startDate)) ||
      (endDate && isSameDay(currentDate, endDate));
    const isInRange =
      startDate &&
      endDate &&
      currentDate > startDate &&
      currentDate < startOfDay(endDate);

    return (
      <div key={format(currentDate, 'yyyy-MM-dd')}>
        <button
          data-selected={isSelected}
          data-range={isInRange}
          className="flex h-11 w-11 items-center justify-center rounded text-sm font-normal text-zinc-800 transition-all hover:border hover:border-stone-300 active:bg-zinc-900 active:text-white data-[range=true]:bg-gray-200 data-[selected=true]:bg-zinc-800 data-[selected=true]:text-white"
          onClick={() => onChange(currentDate)}
        >
          {day}
        </button>
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-1">
      <div className="grid h-fit grid-cols-7 grid-rows-1 gap-2">
        {DAYS_OF_WEEK.map((header) => (
          <div
            key={header}
            className="text-center text-xs font-normal text-zinc-400"
          >
            {header}
          </div>
        ))}
      </div>
      <div className="grid grid-cols-7 gap-y-1">
        {calendarDaysGrid.map(renderDay)}
      </div>
    </div>
  );
}
