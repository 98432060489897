import { gql } from '../__generated__/gql';
export const SHARE_INSIGHT = gql(/* GraphQL */ `
  mutation ShareInsight($id: UUID!) {
    shareInsight(id: $id) {
      id
      contact {
        id
        firstName
        lastName
        email
        phone
      }
      createdAt
      followup
      msgRead
      signal
      hasMessages
      summary
      shareId
    }
  }
`);
