import { gql } from '../__generated__/gql';

export const LIST_TAGS = gql(/* GraphQL */ `
  query ListTags {
    tagsV2 {
      id
      name
      color
    }
  }
`);
