import { useMemo } from 'react';

import { useUpgrade } from './manager-upgrade.hooks';

export function useUpgradeSidebar() {
  const { handleToggleUpgrade, hasReachedLimit, loading } = useUpgrade();

  const message = useMemo(() => {
    if (!loading && hasReachedLimit) {
      return "You've reached your monthly response limit.";
    }

    return null;
  }, [hasReachedLimit, loading]);

  return {
    message,
    onUpgrade: handleToggleUpgrade,
  };
}
