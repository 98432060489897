import { gql } from '../__generated__/gql';

export const GET_ORGANIZATION_STATUS = gql(/* GraphQL */ `
  query GetOrganizationStatus {
    companyInfo {
      id
      website
    }
  }
`);
