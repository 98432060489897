import { Chat, cn } from '@/shared-ui';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormProvider } from 'react-hook-form';
import { getTypeOfRenderer } from './project-response-chat.helpers';
import { useProjectChat } from './project-response-chat.hooks';
import ProjectMessageForm from './project-response-form';

type ProjectMessageFormProps = React.ComponentProps<typeof ProjectMessageForm>;

type Props = React.ComponentProps<typeof Chat> &
  Omit<ProjectMessageFormProps, 'onSubmit'> & {
    showWarning?: boolean;
    className?: string;
    accentColor?: string;
    avatarUrl?: string | null;
    scrollToEnd?: boolean;
    insightLoading?: boolean;
    isConversationOver?: boolean;
    onConversationOver?: () => void;
    hideNewLineText?: boolean;
  } & (
    | {
        readonly: true;
        onSubmit?: never;
      }
    | {
        readonly?: false;
        onSubmit: ProjectMessageFormProps['onSubmit'];
      }
  );

export default function ProjectChat({
  disabled,
  loading,
  messages,
  showWarning = false,
  onSubmit,
  optimisticReplyMessageId,
  optimisticSentMessageId,
  className,
  accentColor,
  avatarUrl,
  scrollToEnd = true,
  insightLoading,
  isConversationOver,
  hideNewLineText = false,
  onConversationOver,
  readonly,
}: Props) {
  const {
    showWarn,
    dismissWarn,
    renderAssistantMessage,
    formatUserMessage,
    formValue,
    content,
    lastAssistantMessage,
  } = useProjectChat({
    messages,
    showWarning,
  });

  return (
    <FormProvider {...formValue}>
      <div
        className={cn(
          'flex flex-grow flex-col items-center overflow-hidden border-t border-gray-200 bg-white shadow-sm sm:rounded-2xl sm:border xl:rounded-none xl:rounded-b-2xl xl:border-x xl:border-b xl:border-t-0',
          className
        )}
      >
        <div className="relative min-h-60 w-full flex-grow bg-gray-50">
          <div
            data-loading={loading}
            className="absolute inset-0 overflow-y-auto pt-2 data-[loading=true]:pb-4"
          >
            <Chat
              messages={messages}
              loading={insightLoading}
              optimisticReplyMessageId={optimisticReplyMessageId}
              optimisticSentMessageId={optimisticSentMessageId}
              renderAssistantMessage={renderAssistantMessage}
              formatUserMessage={formatUserMessage}
              avatarUrl={avatarUrl}
              scrollToEnd={scrollToEnd}
            />
          </div>
        </div>

        {showWarn ? (
          <div className="relative h-6 w-full bg-gray-200 text-center">
            <span className="text-xs leading-none text-gray-600">
              AI can make mistakes. Check important info.
            </span>
            <button
              className="absolute right-1.5 top-0.5 h-4 w-4 p-0.5 text-gray-600"
              onClick={dismissWarn}
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>
        ) : null}
        {!readonly ? (
          <div className="w-full border-t border-gray-300 p-4 outline outline-gray-300 sm:rounded-b-2xl">
            <ProjectMessageForm
              questionType={
                lastAssistantMessage
                  ? getTypeOfRenderer(lastAssistantMessage)
                  : undefined
              }
              disabled={disabled || !content}
              onSubmit={onSubmit}
              accentColor={accentColor}
              isConversationOver={isConversationOver}
              hideNewLineText={hideNewLineText}
              onConversationOver={onConversationOver}
            />
          </div>
        ) : null}
      </div>
    </FormProvider>
  );
}
