import { gql } from '../__generated__/gql';

export const GET_DASHBOARD_V2 = gql(/* GraphQL */ `
  query GetDashboardV2($filter: DashboardV2FilterInput!) {
    dashboardV2(filter: $filter) {
      totalGrowthSignals
      totalInsights
      totalRiskSignals
      avgMessageDepth
      summary
    }
  }
`);
