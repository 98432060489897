export const TAG_COLORS = [
  { lightColor: 'bg-indigo-50', darkColor: 'text-indigo-900' },
  { lightColor: 'bg-blue-100', darkColor: 'text-blue-900' },
  { lightColor: 'bg-teal-100', darkColor: 'text-teal-900' },
  { lightColor: 'bg-cyan-100', darkColor: 'text-cyan-900' },
  { lightColor: 'bg-green-100', darkColor: 'text-green-900' },
  { lightColor: 'bg-yellow-50', darkColor: 'text-yellow-900' },
  { lightColor: 'bg-rose-100', darkColor: 'text-rose-900' },
  { lightColor: 'bg-amber-50', darkColor: 'text-amber-900' },
  { lightColor: 'bg-pink-100', darkColor: 'text-pink-900' },
  { lightColor: 'bg-purple-100', darkColor: 'text-purple-900' },
  { lightColor: 'bg-stone-200', darkColor: 'text-stone-900' },
  { lightColor: 'bg-gray-100', darkColor: 'text-gray-900' },
];

export function getTagColorByIndex(index: number) {
  return TAG_COLORS[index % TAG_COLORS.length];
}
