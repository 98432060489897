import { gql } from '../__generated__/gql';

export const ADD_MESSAGE = gql(/* GraphQL */ `
  mutation AddMessage($input: AddMessageInput!) {
    addNewMessage(input: $input) {
      sent {
        id
        content
        createdAt
        finished
        sender
      }
      reply {
        id
        content
        createdAt
        finished
        sender
      }
    }
  }
`);
