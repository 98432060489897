import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

export default function LandingHero() {
  return (
    <section className="container relative mx-auto mt-2 2xl:flex 2xl:flex-1">
      <div className="bg-squares absolute -z-10 h-full w-full rounded-t-2xl bg-zinc-800">
        <div className="absolute inset-y-0 left-0 w-32 rounded-tl-2xl bg-zinc-800"></div>
        <div className="absolute h-96 w-96 rounded-tl-2xl bg-[radial-gradient(circle_at_top_left,_rgba(224,_49,_70,_0.20)_0%,_rgba(224,_49,_70,_0)_70%)]"></div>
      </div>

      <div className="flex w-full flex-col justify-between overflow-hidden lg:flex-row 2xl:my-auto">
        <div className="px-4 py-16 md:w-1/2 md:px-16 md:pt-32">
          <div className="max-w-lg">
            <div>
              <h3 className="font-display flex flex-col text-5xl font-bold text-white">
                <span className="z-10 ml-1.5 text-nowrap">TheySaid AI</span>
                <span className="-mt-0.5 self-start bg-red-500 px-1.5">
                  Surveys
                </span>
              </h3>

              <p className="mt-5 text-lg leading-normal text-white">
                The world's first conversational survey
              </p>
            </div>

            <div className="mt-20 flex items-center gap-4">
              <Link
                className="text-nowrap rounded-md border border-red-400 bg-red-500 p-4 font-extrabold leading-none text-white transition-colors hover:border-red-800 hover:bg-red-800"
                to="/sign-up"
              >
                Get Started Free
                <FontAwesomeIcon className="ml-3" icon={faArrowRight} />
              </Link>

              <Link
                className="text-nowrap rounded-md border border-zinc-500 bg-zinc-600 p-4 font-extrabold leading-none text-white transition-colors hover:border-zinc-700 hover:bg-zinc-700"
                to="/login"
              >
                Sign In
              </Link>
            </div>

            <div className="mt-4">
              <p className="text-sm leading-normal text-white">
                By continuing, you agree to our{' '}
                <a
                  className="underline"
                  href="https://www.theysaid.io/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms
                </a>{' '}
                and{' '}
                <a
                  className="underline"
                  href="https://www.theysaid.io/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
                .
              </p>
            </div>

            <div className="mt-6 flex items-center gap-5">
              <a
                href="https://www.g2.com/products/theysaid-inc-theysaid/reviews"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  className="h-auto w-48"
                  src="/img/g2-badge.svg"
                  alt="G2 Rating badge"
                />
              </a>
              <img
                className="h-12"
                src="/img/soc2-badge.svg"
                alt="SOC II Type II badge"
              />
              <a
                href="https://www.producthunt.com/posts/theysaid?embed=true&utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-theysaid"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=476202&theme=light&period=daily"
                  alt="TheySaid - Conversational&#0032;surveys&#0032;that&#0032;deliver&#0032;deeper&#0032;insights | Product Hunt"
                />
              </a>
            </div>
          </div>
        </div>

        <div className="p-8 md:p-16 lg:px-32 2xl:px-48">
          <div className="relative flex flex-col items-center">
            <div className="absolute -bottom-20 -z-10 h-[40rem] w-[40rem] rounded-full bg-[radial-gradient(rgba(224,_49,_70,_0.30)_0%,_rgba(224,_49,_70,_0)_70%)]" />
            <div className="absolute bottom-0 -z-10 h-4/5 w-80 rounded-t-2xl bg-red-800/70">
              <img
                className="absolute -left-80 -top-28 -z-10 h-[130%]"
                src="/img/hero-lines.svg"
                alt="Lines"
              />
            </div>
            <img
              className="-mb-28 h-[32rem]"
              src="/img/hero-illustration.png"
              alt="Screenshots of TheySaid AI, an AI customer concierge. One screen shows a chat with a user discussing feedback on some survey response, while the other screen shows a survey question providing 1 to 5 possible ratings."
            />

            <div className="grid min-w-96 max-w-[30rem] grid-cols-1 gap-4 rounded-2xl bg-zinc-950 px-4 py-6 lg:grid-cols-3">
              <div>
                <span className="font-display block text-nowrap text-center text-3xl font-bold tracking-tight text-white">
                  50-100x
                </span>
                <span className="block text-center text-xs leading-tight text-zinc-50">
                  More responses than traditional surveys & NPS
                </span>
              </div>
              <div>
                <span className="font-display block text-nowrap text-center text-3xl font-bold tracking-tight text-white">
                  1-5%
                </span>
                <span className="block text-center text-xs leading-tight text-zinc-50">
                  NRR Improvement
                </span>
              </div>
              <div>
                <span className="font-display block text-nowrap text-center text-3xl font-bold tracking-tight text-white">
                  34%
                </span>
                <span className="block text-center text-xs leading-tight text-zinc-50">
                  More actionable feedback
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
