interface ColorInputProps {
  label?: string;
  value: string;
  onChange: (color: string) => void;
}

export default function ColorInput({
  label,
  value,
  onChange,
}: ColorInputProps) {
  return (
    <div className="flex flex-col gap-2">
      <label className="font-display text-sm font-medium leading-tight tracking-tight text-zinc-800">
        {label}
      </label>
      <div className="flex items-center rounded border border-zinc-200">
        <input
          type="color"
          value={value}
          onChange={(event) => onChange(event.target.value)}
          className="h-8 w-16 self-stretch overflow-hidden rounded-bl-md rounded-tl-md border-0 outline-0"
        />
        <p className="py-1.5 ps-2 font-sans text-sm leading-tight text-zinc-900">
          {value}
        </p>
      </div>
    </div>
  );
}
