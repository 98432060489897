import { forwardRef } from 'react';

import { cn } from './ui.helpers';

import TableHeader from './ui.table-header';
import TableFooter from './ui.table-footer';
import TableBody from './ui.table-body';
import TableHead from './ui.table-head';
import TableRow from './ui.table-row';
import TableCell from './ui.table-cell';
import TableCaption from './ui.table-caption';

const Table = forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement>
>(({ className, ...props }, ref) => (
  <div className="relative w-full overflow-auto">
    <table
      ref={ref}
      className={cn('w-full caption-bottom text-sm', className)}
      {...props}
    />
  </div>
));
Table.displayName = 'Table';

export {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
};
