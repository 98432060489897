import { memo } from 'react';
import withAuthenticationRequired from './auth.with-auth-required';

interface AuthGuardProps {
  component: React.ComponentType;
}

function AuthGuard({ component }: AuthGuardProps) {
  const Component = withAuthenticationRequired(component);
  return <Component />;
}

export default memo(AuthGuard);
