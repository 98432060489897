import Markdown from 'react-markdown';
import rehypeExtLinks from 'rehype-external-links';
import { ensureTripleBackticks } from './project-response-message-renderers.helpers';
import { ProjectResponseComponentProps } from './project-response-renderer.type';

export default function ProjectResponseOpenEndRendererComponent({
  text,
}: ProjectResponseComponentProps) {
  return (
    <div
      data-sender="assistant"
      className="prose animate-slide-in-from-bottom float-right w-full self-stretch break-words rounded-xl border border-zinc-200 bg-white p-3 text-left text-sm leading-tight text-gray-600 transition-opacity group-[.user]:bg-gray-200 data-[sender=assistant]:pt-5 data-[optimistic=true]:opacity-90"
    >
      <Markdown rehypePlugins={[[rehypeExtLinks, { target: '_blank' }]]}>
        {ensureTripleBackticks(text)}
      </Markdown>
    </div>
  );
}
