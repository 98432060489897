import {
  ProjectChat,
  ProjectPoweredBy,
  ProjectResponseProgressBar,
} from '@/project-response';
import { ComponentProps } from 'react';
import ManagerBrandingPreviewSection from './manager-branding-preview-section';
import {
  DEFAULT_THEYSAID_LOGO_URL,
  SAMPLE_MESSAGES,
  SAMPLE_PROGRESS,
} from './manager-branding.helpers';

export default function ManagerBrandingDesktopPreview({
  accentColor,
  avatarUrl,
  logoUrl,
  showPoweredBy,
}: ComponentProps<typeof ManagerBrandingPreviewSection>) {
  return (
    <div className="animate-fade-in flex flex-col gap-0 rounded-xl bg-gray-50 p-6">
      <img
        src="/img/desktop-browser-header.svg"
        alt="Desktop browser header"
        className="h-9 w-full object-cover"
      />
      <div
        className="relative -mt-0.5 flex w-full flex-col items-center gap-1 self-stretch rounded-lg px-16 py-6"
        style={{
          background: accentColor
            ? `linear-gradient(to bottom left, white 40%, ${accentColor})`
            : 'linear-gradient(to bottom left, white 45%, #ffe8ec 55%, #e8edff)',
        }}
      >
        <img
          src={logoUrl ?? DEFAULT_THEYSAID_LOGO_URL}
          alt="Logo"
          data-default={!logoUrl}
          className="absolute left-4 top-8 w-28 scale-75 data-[default=true]:brightness-0 sm:mx-auto sm:mt-0"
        />
        <div className="flex w-full scale-75 items-center gap-2 rounded-t-2xl border border-gray-200 bg-white px-4 py-4">
          <div className="w-full flex-1">
            <ProjectResponseProgressBar
              progress={SAMPLE_PROGRESS}
              accentColor={accentColor ?? undefined}
            />
          </div>
        </div>

        <ProjectChat
          accentColor={accentColor ?? undefined}
          avatarUrl={avatarUrl ?? undefined}
          className="-mt-3 h-[28rem] origin-top scale-75 self-stretch !rounded-t-none"
          messages={SAMPLE_MESSAGES}
          scrollToEnd={false}
          onSubmit={Promise.resolve}
        />

        <div
          data-hidden={!showPoweredBy}
          className="-mt-24 data-[hidden=true]:invisible"
        >
          <ProjectPoweredBy />
        </div>
      </div>
    </div>
  );
}
