import { faChevronUp } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { forwardRef } from 'react';
import { cn } from './ui.helpers';
import { ScrollUpButton } from '@radix-ui/react-select';

const SelectScrollUpButton = forwardRef<
  React.ElementRef<typeof ScrollUpButton>,
  React.ComponentPropsWithoutRef<typeof ScrollUpButton>
>(({ className, ...props }, ref) => (
  <ScrollUpButton
    ref={ref}
    className={cn(
      'flex cursor-default items-center justify-center py-1',
      className
    )}
    {...props}
  >
    <FontAwesomeIcon icon={faChevronUp} className="h-4 w-4" />
  </ScrollUpButton>
));

export default SelectScrollUpButton;
