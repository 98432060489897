import { gql } from '../__generated__/gql';

export const GET_QUESTIONS_WITH_STATS_V2 = gql(/* GraphQL */ `
  query GetQuestionsWithStatsV2($input: QuestionsV2Input!) {
    questionsV2(input: $input) {
      id
      aiConvoDepth
      aiInsights
      multipleSelect
      options {
        id
        label
        value
      }
      randomize
      required
      text
      tags {
        id
        name
      }
    }
  }
`);
