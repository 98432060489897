import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';

const cache = new InMemoryCache();

export default function getApolloClient(apiHost: string) {
  const isLocalhost = apiHost.startsWith('localhost');

  const httpLink = createHttpLink({
    uri: `${isLocalhost ? 'http' : 'https'}://${apiHost}/survey/graphql`,
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: `${isLocalhost ? 'ws' : 'wss'}://${apiHost}/survey/graphql`,
    })
  );

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink
  );

  return new ApolloClient({
    cache,
    link,
  });
}
