import { z } from 'zod';

export const URL_PATTERN =
  /^(https?:\/\/)([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

export const companyInfoFormSchema = z.object({
  orgName: z.string({ required_error: 'Required field' }).min(1),
  website: z
    .string()
    .transform((value) =>
      value?.toLowerCase().startsWith('http')
        ? value
        : ['https://', value].join('')
    )
    .refine(
      (transformedValue) => URL_PATTERN.test(transformedValue?.toLowerCase()),
      {
        message: 'Invalid url. Please enter a valid url.',
      }
    ),
  jobTitle: z.string().min(1),
  numberOfPeople: z.string().min(1),
  tryingFor: z.array(z.string().min(1)).min(1),
});

export type CompanyInfoFormValues = z.infer<typeof companyInfoFormSchema>;

export const SYSTEM_TAG = 'system';
