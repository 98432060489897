import { useAuth } from '@workos-inc/authkit-react';
import { useEffect } from 'react';

import Redirecting from './auth.redirecting';

export default function SignOutPage() {
  const { signOut } = useAuth();

  useEffect(() => {
    signOut();
  }, [signOut]);

  return <Redirecting />;
}
