import { addMonths } from 'date-fns';
import { useCallback, useEffect, useReducer, useRef, useState } from 'react';

import { getMonthAndYearToShow } from './ui.date-range-picker.helpers';

export function useViewport() {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return { width, height };
}

export function useDateRangePicker({
  startDate,
  endDate,
  onChange,
}: {
  startDate: Date;
  endDate: Date;
  onChange: (startDate: Date, endDate: Date) => void;
}) {
  const [open, toggle] = useReducer((open) => !open, false);
  const [pendingStartDate, setPendingStartDate] = useState<Date | null>(
    startDate
  );
  const [pendingEndDate, setPendingEndDate] = useState<Date | null>(endDate);

  const dateRangeRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(() => {
    setPendingStartDate(startDate);
    setPendingEndDate(endDate);
    if (open) toggle();
  }, [endDate, startDate, open]);

  useClickOutsideHandler(dateRangeRef.current, handleClickOutside);

  const [calendarSelectedMonthOfYear, setCalendarSelectedMonthOfYear] =
    useState<[number, number]>(getMonthAndYearToShow(startDate, endDate));
  const dateOfSelectedMonthAndYear = new Date(
    calendarSelectedMonthOfYear[1],
    calendarSelectedMonthOfYear[0]
  );
  const nextMonthFronCalendarSelectedMonthOfYear = [
    addMonths(dateOfSelectedMonthAndYear, 1).getMonth(),
    addMonths(dateOfSelectedMonthAndYear, 1).getFullYear(),
  ];

  function handleChange(date: Date) {
    if (pendingStartDate && pendingEndDate) {
      setPendingEndDate(null);
      setPendingStartDate(date);
    } else if (pendingStartDate) {
      if (pendingStartDate.getTime() > date.getTime()) {
        setPendingEndDate(pendingStartDate);
        setPendingStartDate(date);
        onChange(date, pendingStartDate);
      } else {
        setPendingEndDate(date);
        onChange(pendingStartDate, date);
      }
      setTimeout(() => {
        toggle();
        setCalendarSelectedMonthOfYear(getMonthAndYearToShow(date, date));
      }, 60);
    }
  }

  function handleChangeFromLeftPanelOptions(startDate: Date, endDate: Date) {
    setPendingStartDate(startDate);
    setPendingEndDate(endDate);
    onChange(startDate, endDate);
    setTimeout(() => {
      toggle();
      setCalendarSelectedMonthOfYear(getMonthAndYearToShow(startDate, endDate));
    }, 60);
  }

  const handleNextMonthSelection = useCallback(() => {
    setCalendarSelectedMonthOfYear((prev) => {
      if (prev[0] === 11) {
        return [0, prev[1] + 1];
      }
      return [prev[0] + 1, prev[1]];
    });
  }, []);

  const handlePreviousMonthSelection = useCallback(() => {
    setCalendarSelectedMonthOfYear((prev) => {
      if (prev[0] === 0) {
        return [11, prev[1] - 1];
      }
      return [prev[0] - 1, prev[1]];
    });
  }, []);

  const showSecondCalendarThresholdWidth = 1147;

  return {
    open,
    toggle,
    pendingStartDate,
    pendingEndDate,
    dateRangeRef,
    calendarSelectedMonthOfYear,
    nextMonthFronCalendarSelectedMonthOfYear,
    handleChange,
    handleChangeFromLeftPanelOptions,
    handleNextMonthSelection,
    handlePreviousMonthSelection,
    showSecondCalendarThresholdWidth,
  };
}

export function useClickOutsideHandler(
  target: HTMLElement | null,
  callback: (event: MouseEvent) => void
) {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (target && !target.contains(event.target as Node)) {
        callback(event);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback, target]);
}
