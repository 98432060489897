interface ManagerBrandingPreviewOptionProps {
  icon: React.ReactNode;
  onClick: () => void;
  selected?: boolean;
}

export function ManagerBrandingPreviewOption({
  icon,
  onClick,
  selected,
}: ManagerBrandingPreviewOptionProps) {
  return (
    <button
      data-selected={selected}
      className="inline-flex h-10 w-10 items-center justify-center gap-1 rounded border border-zinc-200 bg-neutral-50 p-2 text-gray-500 transition-colors data-[selected=true]:border-zinc-800 data-[selected=true]:bg-white data-[selected=true]:text-zinc-800 data-[selected=false]:hover:bg-neutral-100"
      onClick={onClick}
    >
      {icon}
    </button>
  );
}
