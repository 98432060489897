import { faCalendar, faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { formatRangeText } from './ui.date-range-picker.helpers';

interface DateRangePickerIndicatorProps {
  startDate: Date;
  endDate: Date;
}

export default function DateRangePickerIndicator({
  startDate,
  endDate,
}: DateRangePickerIndicatorProps): JSX.Element {
  return (
    <div className="flex h-8 items-center gap-2 rounded-sm border border-zinc-200 px-3 py-1 text-sm shadow">
      <FontAwesomeIcon icon={faCalendar} className="mb-0.5 text-zinc-400" />
      <span className="text-sm font-normal leading-tight text-zinc-800">
        {formatRangeText(startDate, endDate)}
      </span>
      <FontAwesomeIcon icon={faChevronDown} className="text-zinc-400" />
    </div>
  );
}
