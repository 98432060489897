interface ProjectResponseProgressBarProps {
  progress: number;
  accentColor?: string;
}

export default function ProjectResponseProgressBar({
  progress,
  accentColor,
}: ProjectResponseProgressBarProps) {
  return (
    <div className="flex w-full items-center justify-center rounded">
      <div className="relative top-0.5 flex h-4 w-full flex-col items-start justify-start gap-2 self-stretch rounded bg-gray-100">
        <div
          className="h-4 rounded bg-gradient-to-r from-rose-200 to-indigo-200 transition-all"
          style={{
            width: `min(${progress * 100}%, 100%)`,
            background: accentColor ?? undefined,
          }}
        />
      </div>
    </div>
  );
}
