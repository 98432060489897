import {
  FontAwesomeIcon,
  type FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { Item } from '@radix-ui/react-dropdown-menu';
import { NavLink, type NavLinkProps } from 'react-router-dom';

interface Props {
  icon: FontAwesomeIconProps['icon'];
  text: React.ReactNode;
  to: NavLinkProps['to'];
}

export default function SidebarFooterMenuItem({ icon, text, to }: Props) {
  return (
    <Item asChild>
      <NavLink
        to={to}
        className="w-full rounded-xl px-4 py-2 text-zinc-900 transition-colors last:text-red-700 hover:bg-zinc-100 focus:outline-none"
      >
        <FontAwesomeIcon icon={icon} size="lg" />
        <span className="ml-3 w-full text-sm font-medium leading-tight">
          {text}
        </span>
      </NavLink>
    </Item>
  );
}
