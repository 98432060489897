import type { GetUpgradeDataQuery } from '@/manager-graphql';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/shared-ui';

interface CheckoutSuccessDialogProps {
  planDetails: GetUpgradeDataQuery['companyInfo']['planDetails'];
}

export const CheckoutSuccessDialog = ({
  planDetails,
}: CheckoutSuccessDialogProps) => {
  return (
    <Dialog defaultOpen>
      <DialogContent>
        <img
          src="/img/payment-success-header.png"
          alt="Arc"
          className="rounded-t-lg rounded-tl-lg"
        />
        <DialogTitle className="font-display px-8 py-2 pt-8 text-center text-2xl font-semibold leading-tight">
          Congratulations
        </DialogTitle>
        <DialogDescription className="px-8 text-center text-sm text-gray-400">
          With your upgrade you have access to{' '}
          {planDetails?.totalAllowedInsights} insights per month and access to
          the additional features.
        </DialogDescription>
        <div className="w-full px-8 py-8">
          <DialogClose asChild>
            <Button variant="destructive" className="w-full">
              Finish
            </Button>
          </DialogClose>
        </div>
      </DialogContent>
    </Dialog>
  );
};
