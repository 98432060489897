import { gql } from '../__generated__/gql';

export const UPDATE_TAG_V2 = gql(/* GraphQL */ `
  mutation UpdateTagV2($input: UpdateTagV2Input!) {
    updateTagV2(input: $input) {
      id
      name
      color
    }
  }
`);
