import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Chip } from '@/shared-ui';
import { useSidebar } from './manager-sidebar.hooks';

interface Props extends React.ComponentProps<'a'> {
  alert?: number | null;
  icon: IconDefinition;
}

export default function AnchorLink({ alert, children, icon, ...props }: Props) {
  const { sidebarState } = useSidebar();

  return (
    <a
      data-state={sidebarState}
      className="relative flex h-10 w-10 items-center rounded-md text-zinc-400 transition-colors hover:bg-zinc-50 group-hover/sidebar:w-full data-[state=fixed]:w-full"
      {...props}
    >
      <span className="w-10 text-center">
        <FontAwesomeIcon icon={icon} size="lg" />
      </span>

      <span
        data-state={sidebarState}
        className="absolute ml-10 w-0 overflow-hidden truncate font-medium tracking-wide group-hover/sidebar:w-40 data-[state=fixed]:w-40"
      >
        {children}
      </span>

      {typeof alert === 'number' ? (
        <div
          data-state={sidebarState}
          className="absolute right-1 top-1 group-hover/sidebar:right-4 group-hover/sidebar:top-auto data-[state=fixed]:right-4 data-[state=fixed]:top-auto"
        >
          <Chip content={alert} />
        </div>
      ) : null}
    </a>
  );
}
