import { forwardRef } from 'react';

import { cn } from './ui.helpers';

const Input = forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement>
>(({ className, type, ...props }, ref) => {
  return (
    <input
      className={cn(
        'flex h-10 w-full rounded-md border px-3 py-2 text-base font-normal leading-normal text-gray-900 file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-sm placeholder:leading-tight placeholder:text-gray-400 disabled:cursor-not-allowed disabled:opacity-50 md:text-sm',
        className
      )}
      ref={ref}
      {...props}
    />
  );
});

export default Input;
