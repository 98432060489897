import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { useLayoutEffect } from 'react';
import ProjectResponseRankingItem from './project-response-ranking-item';
interface ProjectResponseRankingProps {
  values: {
    label: string;
    value: string;
  }[];
  value: string;
  onChange?: (values: string) => void;
  readonly?: boolean;
}

export default function ProjectResponseRanking({
  value,
  values,
  onChange,
  readonly,
}: ProjectResponseRankingProps) {
  const valueAsArray = value.split(',').map((value) => ({
    label: values.find((e) => e.value === value)?.label ?? '',
    value,
  }));
  const valueIndexes = valueAsArray.map((value) =>
    values.findIndex((e) => e.label === value.label)
  );

  useLayoutEffect(() => {
    if (value === '' && !readonly) {
      onChange?.(values.map((e) => e.value).join(','));
    }
  }, [values, value, onChange, readonly]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!active || !over) return;
    if (active?.id !== over?.id) {
      const oldIndex = valueAsArray.findIndex((e) => e.value === active.id);
      const newIndex = valueAsArray.findIndex((e) => e.value === over.id);
      const newItems = arrayMove(valueAsArray, oldIndex, newIndex);
      onChange?.(newItems.map((e) => e.value).join(','));
    }
  };
  if (readonly) {
    return (
      <div className="ms-5 flex flex-col items-start justify-center gap-2 rounded-lg border border-gray-200 bg-white p-4">
        {valueAsArray.map((value, index) => (
          <ProjectResponseRankingItem
            key={value.value}
            value={value.value}
            tag={String.fromCharCode(65 + valueIndexes[index])}
            disabled
            id={value.value}
            label={value.label}
          />
        ))}
      </div>
    );
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={valueAsArray.map((e) => e.value)}>
        <div className="ms-5 flex touch-none flex-col items-start justify-center gap-2 rounded-lg border border-gray-200 bg-white p-4">
          {valueAsArray.map((value, index) => (
            <ProjectResponseRankingItem
              key={value.value}
              value={value.value}
              tag={String.fromCharCode(65 + valueIndexes[index])}
              id={value.value}
              label={value.label}
            />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
}
