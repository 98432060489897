import { Link } from 'react-router-dom';

export default function LandingHeader() {
  return (
    <header className="w-full bg-white sm:px-4">
      <div className="container mx-auto flex items-center justify-between px-4 py-6 sm:px-7">
        <img
          alt="TheySaid Logo"
          className="w-32"
          src="/img/theysaid-logo-colored-horizontal.svg"
        />

        <nav className="flex gap-2">
          <Link
            className="text-nowrap rounded px-4 py-2 text-sm font-medium leading-normal tracking-wide text-zinc-950 transition-colors hover:bg-zinc-100"
            to="/login"
          >
            Login
          </Link>

          <Link
            className="text-nowrap rounded border px-4 py-2 text-sm font-medium leading-normal tracking-wide text-zinc-950 transition-colors hover:bg-zinc-100 "
            to="/sign-up"
          >
            Get Started - Free
          </Link>
        </nav>
      </div>
    </header>
  );
}
