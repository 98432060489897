import { gql } from '../__generated__/gql';

export const CREATE_ORGANIZATION = gql(/* GraphQL */ `
  mutation CreateOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      id
      name
    }
  }
`);
