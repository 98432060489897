import { Group, Label, Root, Value } from '@radix-ui/react-select';
import { forwardRef } from 'react';
import { cn } from './ui.helpers';

import SelectItem from './ui.select-item';
import SelectSeparator from './ui.select-separator';
import SelectContent from './ui.select-content';
import SelectTrigger from './ui.select-trigger';
import SelectScrollDownButton from './ui.select-scroll-down-button';
import SelectScrollUpButton from './ui.select-scroll-up-button';

const Select = Root;

const SelectGroup = Group;

const SelectValue = Value;

const SelectLabel = forwardRef<
  React.ElementRef<typeof Label>,
  React.ComponentPropsWithoutRef<typeof Label>
>(({ className, ...props }, ref) => (
  <Label
    ref={ref}
    className={cn('py-1.5 pl-8 pr-2 text-sm font-semibold', className)}
    {...props}
  />
));

export {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectScrollDownButton,
  SelectScrollUpButton,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
};
