import { forwardRef } from 'react';

import Button from './ui.button';
import { cn } from './ui.helpers';

interface Props extends Omit<React.ComponentProps<typeof Button>, 'variant'> {
  selected: boolean;
}

const Toggle = forwardRef<HTMLButtonElement, Props>(
  ({ className, selected, ...rest }, forwardedRef) => {
    return (
      <Button
        className={cn(
          'bg-gray-50 font-normal data-[state=selected]:border-slate-800 data-[state=selected]:bg-white',
          className
        )}
        data-state={selected ? 'selected' : undefined}
        ref={forwardedRef}
        variant="outline"
        {...rest}
      />
    );
  }
);

export default Toggle;
