import { forwardRef } from 'react';
import { cn } from './ui.helpers';

const TableRow = forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement>
>(({ className, ...props }, ref) => (
  <tr
    ref={ref}
    className={cn(
      'group/row border-white transition-colors data-[state=selected]:bg-gray-50 [&:not(:last-child)]:border-b-4',
      className
    )}
    {...props}
  />
));
TableRow.displayName = 'TableRow';

export default TableRow;
