import { gql } from '../__generated__/gql';

export const GET_INSIGHT_BY_SHARE = gql(/* GraphQL */ `
  query GetInsightByShare($id: UUID!) {
    insightByShare(id: $id) {
      id
      shareId
      messages {
        id
        content
        createdAt
        sender
        metadata {
          isConversationOver
          multipleSelect
          options {
            label
            value
          }
          required
          type
        }
      }
      contact {
        id
        firstName
        lastName
        email
        phone
      }
      progress
    }
  }
`);
