import {
  FieldErrors,
  UseFormRegister,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';

import { cn } from '@/shared-ui';
import { CompanyInfoFormValues } from './manger-onboarding.helpers';

interface InputProps {
  name: keyof CompanyInfoFormValues;
  label: string;
  register: UseFormRegister<CompanyInfoFormValues>;
  watch: UseFormWatch<CompanyInfoFormValues>;
  trigger: UseFormTrigger<CompanyInfoFormValues>;
  errors: FieldErrors<CompanyInfoFormValues>;
  touchedFields: Partial<
    Record<keyof CompanyInfoFormValues, boolean | boolean[]>
  >;
  showErrors: boolean;
  isSubmitting: boolean;
  isCompanyInfoLoading: boolean;
  saveToLocalStorage: (data: Partial<CompanyInfoFormValues>) => void;
}

export function ManagerOnboardingComponentInfoInput({
  name,
  label,
  register,
  watch,
  trigger,
  errors,
  touchedFields,
  showErrors,
  isSubmitting,
  isCompanyInfoLoading,
  saveToLocalStorage,
}: InputProps) {
  return (
    <div key={name} className="flex flex-col gap-2">
      <label
        htmlFor={name}
        className="text-sm font-semibold leading-tight text-gray-600"
      >
        {label}
      </label>
      <input
        {...register(name, {
          onBlur: () => {
            if (name === 'website' && watch('website')) {
              trigger('website');
            }
            saveToLocalStorage({ [name]: watch(name) });
          },
        })}
        className={cn(
          'inline-flex h-14 items-center justify-start gap-2 rounded-lg border border-[#dadce1] bg-white px-6 py-4 shadow',
          isSubmitting && 'opacity-50'
        )}
        name={name}
        readOnly={isSubmitting || isCompanyInfoLoading}
        onChange={(e) => {
          if (name === 'website' && e.target.value) {
            trigger('website');
          }
          saveToLocalStorage({ [name]: e.target.value });
        }}
      />
      {(showErrors || (name === 'website' && watch('website'))) &&
        touchedFields[name] &&
        errors[name]?.message && (
          <div className="text-sm text-red-600">{errors[name]?.message}</div>
        )}
    </div>
  );
}
