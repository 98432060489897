import {
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  getQuarter,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns';

export function isThisMonth(startDate: Date, endDate: Date): boolean {
  const now = new Date();
  const firstDayOfMonth = startOfMonth(now);
  const lastDayOfMonth = endOfMonth(now);
  return (
    startOfDay(startDate).getTime() === startOfDay(firstDayOfMonth).getTime() &&
    startOfDay(endDate).getTime() === startOfDay(lastDayOfMonth).getTime()
  );
}

export function isLastMonth(startDate: Date, endDate: Date): boolean {
  const now = new Date();
  const firstDayOfLastMonth = startOfMonth(subMonths(now, 1));
  const lastDayOfLastMonth = endOfMonth(subMonths(now, 1));

  return (
    startOfDay(startDate).getTime() ===
      startOfDay(firstDayOfLastMonth).getTime() &&
    startOfDay(endDate).getTime() === startOfDay(lastDayOfLastMonth).getTime()
  );
}

export function isThisYear(startDate: Date, endDate: Date): boolean {
  const now = new Date();
  const firstDayOfYear = startOfYear(now);
  const lastDayOfYear = endOfYear(now);

  return (
    startOfDay(startDate).getTime() === startOfDay(firstDayOfYear).getTime() &&
    startOfDay(endDate).getTime() === startOfDay(lastDayOfYear).getTime()
  );
}

export function isLastYear(startDate: Date, endDate: Date): boolean {
  const now = new Date();
  const firstDayOfLastYear = startOfYear(subYears(now, 1));
  const lastDayOfLastYear = endOfYear(subYears(now, 1));

  return (
    startOfDay(startDate).getTime() ===
      startOfDay(firstDayOfLastYear).getTime() &&
    startOfDay(endDate).getTime() === startOfDay(lastDayOfLastYear).getTime()
  );
}

export function isThisWeek(startDate: Date, endDate: Date): boolean {
  const now = new Date();
  const firstDayOfWeek = startOfWeek(now);
  const lastDayOfWeek = endOfWeek(now);

  return (
    startOfDay(startDate).getTime() === startOfDay(firstDayOfWeek).getTime() &&
    startOfDay(endDate).getTime() === startOfDay(lastDayOfWeek).getTime()
  );
}

export function isLastWeek(startDate: Date, endDate: Date): boolean {
  const now = new Date();
  const firstDayOfLastWeek = startOfWeek(subWeeks(now, 1));
  const lastDayOfLastWeek = endOfWeek(subWeeks(now, 1));

  return (
    startOfDay(startDate).getTime() ===
      startOfDay(firstDayOfLastWeek).getTime() &&
    startOfDay(endDate).getTime() === startOfDay(lastDayOfLastWeek).getTime()
  );
}

export function isLast7Days(startDate: Date, endDate: Date): boolean {
  const now = startOfDay(new Date());
  const last7Days = subDays(now, 6);
  return (
    startDate.getTime() === last7Days.getTime() &&
    startOfDay(endDate).getTime() === now.getTime()
  );
}

export function isLast30Days(startDate: Date, endDate: Date): boolean {
  const now = startOfDay(new Date());
  const last30Days = subDays(now, 29);
  return (
    startDate.getTime() === last30Days.getTime() &&
    startOfDay(endDate).getTime() === now.getTime()
  );
}

export function isThisQuarter(startDate: Date, endDate: Date): boolean {
  const now = new Date();
  const thisQuarter = getQuarter(now);
  const firstDayOfQuarter = new Date(now.getFullYear(), (thisQuarter - 1) * 3);
  const lastDayOfQuarter = endOfMonth(
    new Date(now.getFullYear(), (thisQuarter - 1) * 3 + 2)
  );

  return (
    startDate.getTime() === firstDayOfQuarter.getTime() &&
    endDate.getTime() === lastDayOfQuarter.getTime()
  );
}

export function isLastQuarter(startDate: Date, endDate: Date): boolean {
  const now = new Date();
  const lastQuarter = getQuarter(subMonths(now, 3));
  const firstDayOfLastQuarter = new Date(
    now.getFullYear(),
    (lastQuarter - 1) * 3
  );
  const lastDayOfLastQuarter = endOfMonth(
    new Date(now.getFullYear(), (lastQuarter - 1) * 3 + 2)
  );

  return (
    startDate.getTime() === startOfDay(firstDayOfLastQuarter).getTime() &&
    endDate.getTime() === lastDayOfLastQuarter.getTime()
  );
}

export function formatRangeText(startDate: Date, endDate: Date): string {
  const last7days = isLast7Days(startDate, endDate);
  if (last7days) {
    return 'Last 7 days';
  }
  const last30days = isLast30Days(startDate, endDate);
  if (last30days) {
    return 'Last 30 days';
  }
  const thisQuarter = isThisQuarter(startDate, endDate);
  if (thisQuarter) {
    return 'This quarter';
  }
  const lastQuarter = isLastQuarter(startDate, endDate);
  if (lastQuarter) {
    return 'Last quarter';
  }
  const thisYear = isThisYear(startDate, endDate);
  if (thisYear) {
    return 'This year';
  }
  const lastYear = isLastYear(startDate, endDate);
  if (lastYear) {
    return 'Last year';
  }
  if (isHypotheticalAllTime(startDate, endDate)) {
    return 'All time';
  }
  return `${format(startDate.toDateString(), 'dd/MM/yyyy')} - ${format(
    endDate.toDateString(),
    'dd/MM/yyyy'
  )}`;
}

// Helper function to determine the number of days in a given month
export const daysInMonth = (month: number, year: number) => {
  return new Date(year, month + 1, 0).getDate();
};

// Helper function to determine which day of the week the month starts on
export const getStartDay = (month: number, year: number) => {
  return new Date(year, month, 1).getDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6
};

export const isSameDay = (date1: Date, date2: Date) => {
  return startOfDay(date1).getTime() === startOfDay(date2).getTime();
};

export const isHypotheticalAllTime = (startDate: Date, endDate: Date) => {
  return (
    startOfDay(new Date('1970-01-01')).getTime() === startDate.getTime() &&
    endDate.getTime() >= new Date().getTime()
  );
};

export const getMonthAndYearToShow = (startDate: Date, endDate: Date) => {
  return (
    isHypotheticalAllTime(startDate, endDate)
      ? [new Date().getMonth(), new Date().getFullYear()]
      : [endDate.getMonth(), endDate.getFullYear()]
  ) as [number, number];
};

export const fullMonthsText = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
