import { faLoader } from '@fortawesome/pro-light-svg-icons';

import StateMessage from './ui.state-message';

const StateMessageLoading = () => {
  return (
    <div className="flex min-h-[calc(100vh-240px)] min-w-full items-center">
      <StateMessage
        heading="Loading..."
        icon={faLoader}
        iconClassName="animate-spin-slow"
      />
    </div>
  );
};

export default StateMessageLoading;
