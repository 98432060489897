import { PropsWithChildren, ReactNode } from 'react';

type PageContainerProps = PropsWithChildren<{
  header: ReactNode;
}>;

export default function PageContainer({
  header,
  children,
}: PageContainerProps) {
  return (
    <div className="flex w-full flex-col overflow-auto bg-zinc-50 p-6">
      <div className="flex-1 p-6">
        {header}
        <main className="space-y-6 rounded-b-2xl border border-gray-200 bg-white p-6">
          {children}
        </main>
      </div>
    </div>
  );
}
