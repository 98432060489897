import { ProjectResponseOpenEndRendererComponent } from './project-response-renderer-open-end';
import { ProjectResponseComponentProps } from './project-response-renderer.type';
import ProjectResponseSelect from './project-response-select';

export function ProjectResponseSingleSelectRendererComponent({
  text,
  options = [],
  value,
  onChange,
  readonly,
}: ProjectResponseComponentProps) {
  return (
    <div className="flex flex-col gap-2">
      <ProjectResponseOpenEndRendererComponent text={text} />
      <ProjectResponseSelect
        onClick={(value) => onChange?.(value)}
        options={options}
        value={value ?? ''}
        readonly={readonly}
      />
    </div>
  );
}
