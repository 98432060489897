import { useAuth } from '@workos-inc/authkit-react';

import Measurement from './measurement';
import { useMixpanelWithUser } from './measurement.hooks';

export default function MeasurementWithUser({
  token,
}: React.ComponentProps<typeof Measurement>) {
  const { user } = useAuth();
  useMixpanelWithUser(token, user);
  return null;
}
