import { matchPath, useLocation } from 'react-router-dom';

import { useOnboarding } from '@/manager-onboarding';

export function useHomeWrapper() {
  const location = useLocation();
  const { pathname } = location;
  const companyOnboardingPath = '/home/onboarding/company-info';
  const homePath = '/home';
  const companyOnboardingSubmittingPath =
    '/home/onboarding/company-info-submitting';
  const { companyInfo, isLoading } = useOnboarding();
  const shouldRedirectToOnboard =
    !matchPath(companyOnboardingPath, pathname) &&
    !matchPath(companyOnboardingSubmittingPath, pathname) &&
    !companyInfo &&
    !isLoading;

  const shouldRedirectToHome =
    location.pathname.startsWith('/home/onboarding') &&
    !isLoading &&
    companyInfo?.hasOnboarded;

  return {
    shouldRedirectToOnboard,
    companyOnboardingPath,
    shouldRedirectToHome,
    homePath,
    isLoading,
  };
}
