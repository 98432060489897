import { gql } from '../__generated__/gql';

export const CURRENT_USER_ORGANIZATIONS = gql(/* GraphQL */ `
  query CurrentUserOrganizations {
    me {
      organizations {
        id
        name
      }
    }
  }
`);
