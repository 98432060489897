import { forwardRef } from 'react';
import { cn } from './ui.helpers';

const Textarea = forwardRef<
  HTMLTextAreaElement,
  React.TextareaHTMLAttributes<HTMLTextAreaElement>
>(({ className, ...props }, ref) => {
  return (
    <textarea
      className={cn(
        'flex min-h-[80px] w-full rounded-md border px-3 py-2 text-base font-normal leading-normal text-gray-900 placeholder:text-sm placeholder:leading-tight placeholder:text-gray-400 disabled:cursor-not-allowed disabled:opacity-50 md:text-sm',
        className
      )}
      ref={ref}
      {...props}
    />
  );
});

export default Textarea;
