import {
  Close,
  Content,
  Description,
  Overlay,
  Portal,
  Root,
  Title,
  Trigger,
  type DialogContentProps,
} from '@radix-ui/react-dialog';
import { forwardRef } from 'react';

export const DrawerContent = forwardRef<
  HTMLDivElement,
  Omit<DialogContentProps, 'className' | 'ref'>
>(({ children, ...props }, forwardedRef) => {
  return (
    <Portal>
      <Overlay className="data-[state=open]:animate-overlay-show fixed inset-0 z-20 bg-black opacity-30" />
      <Content
        {...props}
        className="data-[state=open]:animate-drawer-show fixed inset-y-0 right-0 z-30 flex max-h-[100vh] w-[36rem] max-w-[80vw] flex-col overflow-hidden rounded-l-lg bg-white shadow-xl focus:outline-none"
        ref={forwardedRef}
      >
        {children}
      </Content>
    </Portal>
  );
});

export const Drawer = Root;
export const DrawerClose = Close;
export const DrawerDescription = Description;
export const DrawerTitle = Title;
export const DrawerTrigger = Trigger;
