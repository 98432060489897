import {
  faArrowUpFromBracket,
  faTrashCan,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { forwardRef } from 'react';
import {
  bytesToSize,
  formatAllowedFilesAndDimension,
} from './ui.image-upload.helpers';
import { useImageUpload } from './ui.image-upload.hooks';

interface ImageUploadProps {
  onChange: (file: File | null) => void;
  onDelete?: () => void;
  value: string | null;
  allowedTypes?: string[];
  maxFileSize?: number;
  maxAllowedResolution?: { width: number; height: number };
  label?: string;
}

const ImageUpload = forwardRef(
  (
    {
      onChange,
      value,
      allowedTypes = [],
      maxFileSize,
      maxAllowedResolution,
      label,
      onDelete,
    }: ImageUploadProps,
    ref: React.Ref<{ reset: () => void }>
  ) => {
    const {
      handleChange,
      handleTrashClick,
      promptUpload,
      imageDimensions,
      imageSize,
      imageName,
      uploadRef,
      imageRef,
      error,
      isDragging,
      handleDrop,
    } = useImageUpload(ref, {
      allowedTypes,
      maxAllowedResolution,
      maxFileSize,
      onChange,
      onDelete,
      value,
    });

    const renderContent = isDragging ? (
      <div className="flex h-24 w-60 flex-col items-center justify-center gap-3 rounded border border-dashed border-gray-500 bg-neutral-50 px-3 py-4 sm:w-72">
        <div className="flex flex-col items-center justify-start gap-2">
          <FontAwesomeIcon
            icon={faArrowUpFromBracket}
            className="h-5 w-5 text-gray-600"
          />
          <div className="font-sans text-xs leading-tight text-zinc-800">
            Drop your image here
          </div>
        </div>
      </div>
    ) : value ? (
      <div
        role="button"
        onClick={promptUpload}
        className="flex w-60 items-center gap-3 self-stretch rounded border border-zinc-200 bg-neutral-50 px-6 py-4 text-gray-600 sm:w-72"
      >
        <img
          src={value}
          alt="Uploaded file"
          className="h-16 w-16 rounded object-contain"
          ref={imageRef}
        />
        <div className="flex flex-col gap-1 self-center text-wrap break-words">
          <span className="text-xs text-zinc-800">
            {imageName ?? 'Uploaded file'}
          </span>
          <span className="text-xs text-gray-500">
            {imageDimensions?.height} x {imageDimensions?.width}{' '}
            {imageSize ? <>• {bytesToSize(imageSize)} KB</> : null}
          </span>
        </div>
      </div>
    ) : (
      <div
        role="button"
        onClick={promptUpload}
        className="flex flex-col items-center justify-center gap-3 self-stretch rounded border border-dashed border-zinc-200 px-6 py-4 text-gray-600 sm:w-72"
      >
        <FontAwesomeIcon
          icon={faArrowUpFromBracket}
          className="h-5 w-5 text-gray-600"
        />
        <div className="font-display flex flex-col gap-1 text-sm leading-tight">
          <h2 className="text-gray-600">
            <span className="font-medium text-gray-800">Click to upload</span>{' '}
            or drag and drop
          </h2>
          <h2 className="text-gray-600">
            {formatAllowedFilesAndDimension({
              allowedTypes,
              dimension: maxAllowedResolution,
            })}
          </h2>
        </div>
      </div>
    );

    return (
      <div className="flex flex-col gap-2 self-stretch" onDrop={handleDrop}>
        {label ? (
          <div className="flex justify-between self-stretch">
            <label className="font-display text-sm font-medium leading-tight tracking-tight text-zinc-800">
              {label}
            </label>
            {value ? (
              <button onClick={handleTrashClick}>
                <FontAwesomeIcon
                  icon={faTrashCan}
                  className="h-4 w-4 text-gray-500"
                />
              </button>
            ) : null}
          </div>
        ) : null}
        {renderContent}
        {error ? (
          <p className="font-sans text-xs leading-tight text-orange-700">
            {error}
          </p>
        ) : null}
        <input
          ref={uploadRef}
          type="file"
          onChange={(e) => handleChange(e.target.files)}
          className="hidden"
        />
      </div>
    );
  }
);

export default ImageUpload;
