import { gql } from '../__generated__/gql';

export const GET_PROJECT = gql(/* GraphQL */ `
  query GetProject($id: UUID!) {
    project(id: $id) {
      id
      title
      lastResponseDate
      published
      redirectUrl
      totalGrowthSignals
      totalInsights
      totalRiskSignals
      totalUnreadInsights
      avgMessageDepth
      questions {
        id
        type
        text
        required
        aiInsights
        aiConvoDepth
        multipleSelect
        randomize
        options {
          id
          label
          value
        }
      }
      tags {
        id
        name
        color
      }
      enableContactInfoCollection
      askContactInformation
    }
  }
`);
