import { faGripDotsVertical } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { forwardRef, Ref } from 'react';

interface SurveyResponseSelectItemProps {
  value: string;
  tag: string;
  selected?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  isDragAndDrop?: boolean;
  label: string;
}

const SurveyResponseSelectItem = forwardRef(
  (
    {
      tag,
      selected = false,
      onClick,
      disabled,
      isDragAndDrop,
      label,
    }: SurveyResponseSelectItemProps,
    ref: Ref<HTMLButtonElement>
  ) => {
    return (
      <button
        onClick={onClick}
        className="flex min-h-10 w-full items-center justify-between gap-4 self-stretch rounded-lg border border-gray-200 bg-gradient-to-r from-rose-50 to-indigo-50 px-3 data-[selected=true]:border-gray-500 data-[selected=true]:bg-white data-[selected=true]:bg-none"
        data-selected={selected}
        disabled={disabled}
        ref={ref}
      >
        <div className="flex items-center gap-2">
          {isDragAndDrop ? (
            <FontAwesomeIcon
              icon={faGripDotsVertical}
              className="h-4 w-4 text-gray-400"
            />
          ) : null}
          <div className="text-start font-sans text-gray-900">{label}</div>
        </div>
        <div className="inline-flex w-5 flex-col items-center justify-center gap-2 rounded border border-gray-200 bg-gray-50 px-1 py-0.5">
          <div className="w-4 text-center font-sans text-xs leading-none text-gray-900">
            {tag}
          </div>
        </div>
      </button>
    );
  }
);

export default SurveyResponseSelectItem;
