import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addMonths } from 'date-fns';

import { fullMonthsText } from './ui.date-range-picker.helpers';
import { useViewport } from './ui.date-range-picker.hooks';

interface DateRangePickerCalendarHeaderProps {
  selectedMonthAndYear: [number, number];
  onNextMonthSelection: () => void;
  onPreviousMonthSelection: () => void;
  widthThreshold: number;
}

export default function DateRangePickerCalendarHeader({
  selectedMonthAndYear,
  onNextMonthSelection,
  onPreviousMonthSelection,
  widthThreshold,
}: DateRangePickerCalendarHeaderProps): JSX.Element {
  const { width } = useViewport();

  const calendarDate = new Date(
    selectedMonthAndYear[1],
    selectedMonthAndYear[0]
  );
  const nextMonth = [
    addMonths(calendarDate, 1).getMonth(),
    addMonths(calendarDate, 1).getFullYear(),
  ];

  return (
    <div className="flex items-center justify-between self-stretch px-1.5">
      <button onClick={onPreviousMonthSelection}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <span className="font-normal text-zinc-800">
        {fullMonthsText[selectedMonthAndYear[0]]} {selectedMonthAndYear[1]}
      </span>
      <span
        data-hide={width < widthThreshold}
        className="invisible w-6 data-[hide=true]:hidden"
      ></span>
      <span
        data-hide={width < widthThreshold}
        className="font-normal text-zinc-800 data-[hide=true]:hidden"
      >
        {fullMonthsText[nextMonth[0]]} {nextMonth[1]}
      </span>
      <button onClick={onNextMonthSelection}>
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  );
}
