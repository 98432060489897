import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CheckoutSuccessDialog } from './manager-upgrade-checkout-success-dialog';
import { UpgradeStripeDialog } from './manager-upgrade-dialog';
import { useUpgrade } from './manager-upgrade.hooks';

export const Upgrade = () => {
  const { planDetails } = useUpgrade();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams?.get('checkout_session_id')) {
      // Using window directly to avoid re-rendering the component
      setTimeout(() => {
        const url = new URL(window.location.href);
        url.searchParams.delete('checkout_session_id');
        window.history.replaceState({}, '', url.toString());
      }, 1000);
    }
  }, [searchParams]);

  return (
    <>
      {!!searchParams?.get('checkout_session_id')?.trim()?.length &&
        planDetails?.planCode && (
          <CheckoutSuccessDialog planDetails={planDetails} />
        )}
      <UpgradeStripeDialog />
    </>
  );
};
