import { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { ModalLayout, Skeleton, cn } from '@/shared-ui';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOnboardingSurvey } from './manager-onboarding-survey.hooks';

export default function OnboardingSurvey() {
  const navigate = useNavigate();
  const {
    surveyId,
    isButtonEnabled,
    handleButtonClick,
    getSurveyUrl,
    companyInfo,
    isCompanyInfoLoading,
    updateLoading,
  } = useOnboardingSurvey();

  useEffect(() => {
    if (!surveyId) {
      navigate('/home');
    }
  }, [surveyId, navigate]);

  if (!surveyId) {
    return null; // Render nothing while redirecting
  }

  const shouldRedirectToHome =
    companyInfo?.insightsCount && companyInfo.insightsCount > 0;

  if (shouldRedirectToHome) {
    return <Navigate replace to={'/home'} />;
  }

  if (isCompanyInfoLoading) {
    return (
      <ModalLayout>
        <div className="flex flex-col gap-4 p-5">
          <Skeleton className="h-14 w-full" />
          <Skeleton className="h-32 w-full" />
        </div>
      </ModalLayout>
    );
  }

  return (
    <ModalLayout
      hideHeader
      title={`I'm almost done learning about ${companyInfo?.name}. While I'm wrapping up, please respond to the question below to test how I work to uncover insights.`}
    >
      <div className="flex h-full flex-col gap-2 py-4 pb-20 sm:pb-12 md:pb-4">
        <div className="flex flex-1 px-4">
          <iframe
            src={getSurveyUrl(surveyId)}
            title="Survey"
            className="w-full self-stretch"
          >
            <p>Your browser does not support iframes.</p>
          </iframe>
        </div>
        <div className="w-full border-gray-200 px-6">
          <button
            type="button"
            className={cn(
              'inline-flex h-14 w-full items-center justify-center rounded-lg bg-gray-800 px-4 py-2 text-base font-normal leading-normal text-white transition-opacity duration-200 ease-in-out',
              !isButtonEnabled && 'cursor-not-allowed opacity-50'
            )}
            disabled={!isButtonEnabled || updateLoading}
            onClick={handleButtonClick}
          >
            {updateLoading ? (
              <FontAwesomeIcon icon={faSpinnerThird} className="animate-spin" />
            ) : (
              'Done testing, let me in'
            )}
          </button>
        </div>
      </div>
    </ModalLayout>
  );
}
