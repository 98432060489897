interface RatingScaleProps {
  options: {
    label: string;
    value: string;
  }[];
  value: string;
  onClick: (value: string) => void;
  readonly?: boolean;
}

export default function RatingScaleSelect({
  options,
  value,
  onClick,
  readonly,
}: RatingScaleProps) {
  const sortedOptions = options.sort((a, b) => +a.value - +b.value);
  const labels = options?.length
    ? [options[0].label, options[options.length - 1].label]
    : ['', ''];
  return (
    <div className="ms-5 min-h-20 flex-col items-center justify-start gap-3 rounded-lg border border-gray-200 bg-white p-3 placeholder:flex">
      <div className="flex min-h-14 flex-col items-start justify-start gap-1 self-stretch">
        <div className="flex flex-wrap items-center justify-center gap-2 self-stretch">
          {sortedOptions.map((v) => (
            <button
              key={v.value}
              data-selected={value === v.value}
              className="flex min-h-10 w-16 shrink grow basis-0 flex-col items-center justify-center gap-2 rounded-lg border border-gray-200 bg-gradient-to-b from-rose-50 to-indigo-50 p-2 data-[small=true]:w-10 data-[small=true]:max-w-10 data-[selected=true]:border-gray-500 data-[selected=true]:bg-white data-[selected=true]:bg-none md:w-24"
              onClick={() => onClick(v.value)}
              data-small={options.length > 5}
              disabled={readonly}
            >
              <div className="text-center font-sans text-gray-900">
                {v.value}
              </div>
            </button>
          ))}
        </div>
        <div className="flex items-start justify-between self-stretch">
          {labels.map((label, index) => (
            <div
              key={index}
              className="text-center font-sans text-xs text-gray-500"
            >
              {label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
