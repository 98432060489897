import { PageContainer, StateMessageLoading } from '@/shared-ui';
import ManagerBrandingCustomizeSection from './manager-branding-customize-section';
import ManagerBrandingHeader from './manager-branding-header';
import ManagerBrandingPreviewSection from './manager-branding-preview-section';
import { useManagerBranding } from './manager-branding.hooks';

export default function ManagerBranding() {
  const {
    accentColor,
    avatarUrl,
    logoUrl,
    poweredBy,
    settingsLoading,
    hasChangeHappened,
    onSubmit,
    onCancel,
    isSaving,
    setValue,
    onDeleteImage,
    customizeSectionRef,
  } = useManagerBranding();

  if (settingsLoading) return <StateMessageLoading />;

  return (
    <PageContainer
      header={
        <ManagerBrandingHeader
          hasChangeHappened={hasChangeHappened}
          onSubmit={onSubmit}
          onCancel={onCancel}
          isSaving={isSaving}
        />
      }
    >
      <div className="flex w-full items-stretch gap-6 self-stretch overflow-x-auto">
        <ManagerBrandingCustomizeSection
          accentColor={accentColor}
          avatarUrl={avatarUrl}
          logoUrl={logoUrl}
          showPoweredBy={poweredBy}
          onChange={setValue}
          onDeleteImage={onDeleteImage}
          ref={customizeSectionRef}
        />
        <ManagerBrandingPreviewSection
          accentColor={accentColor}
          avatarUrl={avatarUrl}
          logoUrl={logoUrl}
          showPoweredBy={poweredBy}
        />
      </div>
    </PageContainer>
  );
}
