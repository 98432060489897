import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import type { ApolloProviderProps } from '@apollo/client/react/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { useAuth } from '@workos-inc/authkit-react';
import { createClient } from 'graphql-ws';

const cache = new InMemoryCache();

const httpLink = createHttpLink({ uri: '/graphql' });

interface Props extends Omit<ApolloProviderProps<InMemoryCache>, 'client'> {
  apiHost: string;
}

const ApolloProviderWithClient = ({ apiHost, children }: Props) => {
  const { getAccessToken, user } = useAuth();

  const getAuthorizationHeader = async () => {
    if (!user) return null;
    try {
      const accessToken = await getAccessToken();
      return `Bearer ${accessToken}`;
    } catch (error) {
      return null;
    }
  };

  const wsLink = new GraphQLWsLink(
    createClient({
      url: apiHost
        ? `ws://${apiHost}/graphql`
        : `wss://${window.location.hostname}/graphql`,
      connectionParams: async () => ({
        authorization: getAuthorizationHeader(),
      }),
    })
  );

  const authLink = setContext(async (_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: await getAuthorizationHeader(),
      },
    };
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink
  );

  const client = new ApolloClient({
    cache,
    link: authLink.concat(splitLink),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloProviderWithClient;
