import { gql } from '../__generated__/gql';

export const GET_UNREAD_INSIGHTS = gql(/* GraphQL */ `
  query GetUnreadInsights {
    projects(first: 1000) {
      items {
        id
        totalUnreadInsights
      }
    }
  }
`);
