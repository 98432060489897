import { ProjectResponseRankingRendererComponent } from './project-response-ranking-renderer';
import { ProjectResponseRatingScaleRendererComponent } from './project-response-rating-scale-renderer';
import { ProjectResponseOpenEndRendererComponent } from './project-response-renderer-open-end';
import {
  ProjectResponseComponentProps,
  RendererOption,
} from './project-response-renderer.type';
import { ProjectResponseSingleSelectRendererComponent } from './project-response-single-select-renderer';
import { ProjectResponseMultiSelectRendererComponent } from './project-response.multi-select-renderer';

export default function ProjectResponseMessageRenderer({
  type,
  ...rest
}: ProjectResponseComponentProps & {
  type: RendererOption;
}) {
  switch (type) {
    case 'ranking':
      return <ProjectResponseRankingRendererComponent {...rest} />;
    case 'rating':
      return <ProjectResponseRatingScaleRendererComponent {...rest} />;
    case 'singleChoice':
      return <ProjectResponseSingleSelectRendererComponent {...rest} />;
    case 'multipleChoice':
      return <ProjectResponseMultiSelectRendererComponent {...rest} />;
    case 'openEnded':
    default:
      return <ProjectResponseOpenEndRendererComponent {...rest} />;
  }
}
