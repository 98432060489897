import { gql } from '../__generated__/gql';

export const GET_UPGRADE_DATA = gql(/* GraphQL */ `
  query GetUpgradeData($insightsCountInput: DashboardFilterInput) {
    companyInfo {
      id
      stripeCustomerId
      insightsCount(filter: $insightsCountInput)
      planDetails {
        planCode
        totalAllowedInsights
      }
    }
  }
`);
