import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DialogTrigger } from '@radix-ui/react-dialog';

import { Button } from '@/shared-ui';
import { UpgradeStripeDialog } from './manager-upgrade-dialog';
import { useUpgradeSidebar } from './manager-upgrade-sidebar.hooks';

interface Props {
  sidebarState: 'collapse' | 'fixed';
}

export default function UpgradeSidebar({ sidebarState }: Props) {
  const { message, onUpgrade } = useUpgradeSidebar();

  if (!message) {
    return null;
  }

  return (
    <>
      <div
        data-state={sidebarState}
        className="m-auto flex h-10 w-10 items-center justify-center rounded-full bg-rose-700 group-hover/sidebar:hidden data-[state=fixed]:hidden"
      >
        <FontAwesomeIcon
          className="text-xl text-white"
          icon={faTriangleExclamation}
        />
      </div>

      <div
        data-state={sidebarState}
        className="m-5 hidden h-80 flex-col gap-5 rounded-md bg-zinc-800 p-4 text-center duration-200 group-hover/sidebar:flex data-[state=fixed]:flex"
      >
        <div className="m-auto flex h-10 w-10 items-center justify-center rounded-full bg-red-500">
          <FontAwesomeIcon
            className="text-xl text-white"
            icon={faTriangleExclamation}
          />
        </div>

        <span className="text-sm font-semibold text-white">Upgrade now</span>
        <span className="max-h-10 text-sm text-gray-400">{message}</span>
        <span className="max-h-14 text-sm text-gray-400">
          Upgrade now to unlock more conversations and new features.
        </span>

        <UpgradeStripeDialog>
          <DialogTrigger asChild onClick={onUpgrade}>
            <Button variant="destructive">Upgrade Now</Button>
          </DialogTrigger>
        </UpgradeStripeDialog>
      </div>
    </>
  );
}
