import { gql } from '../__generated__/gql';

export const GET_API_KEYS = gql(/* GraphQL */ `
  query GetApikeys {
    keys {
      id
      name
      createdAt
    }
  }
`);
