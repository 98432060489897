import { Separator } from '@radix-ui/react-select';
import { forwardRef } from 'react';
import { cn } from './ui.helpers';

const SelectSeparator = forwardRef<
  React.ElementRef<typeof Separator>,
  React.ComponentPropsWithoutRef<typeof Separator>
>(({ className, ...props }, ref) => (
  <Separator
    ref={ref}
    className={cn('bg-muted -mx-1 my-1 h-px', className)}
    {...props}
  />
));

export default SelectSeparator;
