import { gql } from '../__generated__/gql';

export const MESSAGES_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription MessageAdded($insightId: UUID!) {
    messageAdded(insightId: $insightId) {
      id
      content
      createdAt
      finished
      sender
    }
  }
`);
