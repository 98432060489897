import { gql } from '../__generated__/gql';

export const CREATE_ORGANIZATION_SETTINGS = gql(/* GraphQL */ `
  mutation CreateOrganizationSettings(
    $input: CreateOrganizationSettingsInput!
  ) {
    createOrgSettings(input: $input) {
      id
    }
  }
`);
