import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ManagerBrandingHeaderProps {
  hasChangeHappened: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  isSaving: boolean;
}

export default function ManagerBrandingHeader({
  hasChangeHappened,
  onSubmit,
  onCancel,
  isSaving,
}: ManagerBrandingHeaderProps) {
  return (
    <header className="flex items-center justify-between gap-2 overflow-x-auto rounded-t-2xl border border-gray-200 bg-white px-6 py-4">
      <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-0.5">
        <h1 className="font-display text-center text-xl font-bold leading-snug text-gray-900">
          Branding
        </h1>
      </div>

      <div className="flex items-center justify-start gap-2 text-nowrap">
        <button
          className="flex items-center justify-center gap-2 rounded bg-gray-50 px-12 py-3 text-sm font-medium leading-none tracking-tight text-zinc-800  transition-colors focus:outline-gray-200 enabled:hover:bg-gray-100 disabled:opacity-90"
          onClick={onCancel}
          disabled={!hasChangeHappened || isSaving}
        >
          Cancel
        </button>
        <button
          className="flex items-center justify-center gap-2 rounded bg-gray-800 px-12 py-3 text-sm font-medium leading-none tracking-tight text-white transition-colors focus:outline-gray-400 enabled:hover:bg-gray-700 disabled:opacity-50"
          onClick={onSubmit}
          disabled={!hasChangeHappened || isSaving}
        >
          {isSaving ? (
            <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
          ) : (
            'Save Changes'
          )}
        </button>
      </div>
    </header>
  );
}
