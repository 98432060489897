import { ProjectResponseOpenEndSendComponent } from './project-response-renderer-open-end';
import {
  ProjectResponseSendComponentProps,
  RendererOption,
} from './project-response-renderer.type';
import ProjectResponseSelectSendButton from './project-response-select-send-button';

export default function ProjectResponseSendFormRenderer({
  type,
  ...rest
}: ProjectResponseSendComponentProps & {
  type: RendererOption;
  hideNewLineText?: boolean;
}) {
  switch (type) {
    case 'ranking':
      return <ProjectResponseSelectSendButton {...rest} />;
    case 'rating':
      return <ProjectResponseSelectSendButton {...rest} />;
    case 'singleChoice':
      return <ProjectResponseSelectSendButton {...rest} />;
    case 'multipleChoice':
      return <ProjectResponseSelectSendButton {...rest} />;
    default:
      return <ProjectResponseOpenEndSendComponent {...rest} />;
  }
}
