import { gql } from '../__generated__/gql';

export const ADD_API_KEY = gql(/* GraphQL */ `
  mutation AddApikey($input: ApikeyAddInput!) {
    addKey(input: $input) {
      id
      name
      createdAt
    }
  }
`);
