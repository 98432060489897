import {
  FontAwesomeIcon,
  type FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { Item } from '@radix-ui/react-dropdown-menu';

interface Props {
  icon: FontAwesomeIconProps['icon'];
  text: React.ReactNode;
  onClick?: () => void;
}

export default function SidebarFooterMenuButtonItem({
  icon,
  text,
  onClick,
}: Props) {
  return (
    <Item asChild onClick={onClick}>
      <div className="w-full cursor-pointer rounded-xl px-4 py-2 text-zinc-900 transition-colors last:text-red-700 hover:bg-zinc-100 focus:outline-none">
        <FontAwesomeIcon icon={icon} size="lg" />
        <span className="ml-3 w-full text-sm font-medium leading-tight">
          {text}
        </span>
      </div>
    </Item>
  );
}
