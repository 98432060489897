import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';

import { StateMessage } from '@/shared-ui';

export default function DashboardEmptyState() {
  return (
    <div className="flex min-h-[80vh] min-w-full items-center">
      <StateMessage
        heading="No insights found"
        icon={faMagnifyingGlass}
        body="There are no insights for the date range selected, try changing the date filter"
      />
    </div>
  );
}
