import {
  Arrow,
  Content,
  Provider,
  Root,
  Trigger,
} from '@radix-ui/react-tooltip';

interface TooltipProps {
  children: React.ReactNode;
  content: string;
}

export default function Tooltip({ children, content }: TooltipProps) {
  return (
    <Provider>
      <Root>
        <Trigger asChild>{children}</Trigger>
        <Content
          className="w-max max-w-44 rounded-lg bg-zinc-800 px-3 py-2 leading-snug text-white"
          sideOffset={4}
        >
          {content}
          <Arrow width={12} height={6} />
        </Content>
      </Root>
    </Provider>
  );
}
