import { useEffect } from 'react';
import { useRecoilSnapshot } from 'recoil';

export default function RecoilDebugObserver() {
  const snapshot = useRecoilSnapshot();
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.debug('DebugObserver mounted');
  }, []);
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.debug('The following atoms were modified:');
    for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
      // eslint-disable-next-line no-console
      console.debug(node.key, snapshot.getLoadable(node));
      // eslint-disable-next-line no-console
      console.debug(node.key, snapshot.getInfo_UNSTABLE(node));
    }
  }, [snapshot]);

  return null;
}
