import {
  addYears,
  endOfQuarter,
  endOfYear,
  startOfDay,
  startOfQuarter,
  startOfYear,
  subDays,
  subQuarters,
  subYears,
} from 'date-fns';

import {
  isHypotheticalAllTime,
  isLast30Days,
  isLast7Days,
  isLastQuarter,
  isLastYear,
  isThisQuarter,
  isThisYear,
} from './ui.date-range-picker.helpers';

interface DateRangePickerLeftPanelProps {
  startDate: Date;
  endDate: Date;
  onChange: (startDate: Date, endDate: Date) => void;
}

export default function DateRangePickerLeftPanel({
  startDate,
  endDate,
  onChange,
}: DateRangePickerLeftPanelProps): JSX.Element {
  const leftPanelButtonClassName =
    'sm:p-2.5 p-2 data-[selected=true]:font-medium sm:outline-none rounded-md outline outline-zinc-100 shadow-sm sm:shadow-none font-normal data-[selected=true]:bg-neutral-100 hover:outline hover:outline-zinc-200 active:bg-neutral-100 transition-colors sm:w-full text-start sm:rounded';

  return (
    <div className="flex flex-wrap items-start gap-2 border-r border-zinc-200 px-6 pb-4 text-xs sm:w-auto sm:flex-col sm:px-4 sm:py-4 sm:pb-0 sm:text-inherit">
      <button
        data-selected={isLast7Days(startDate, endDate)}
        className={leftPanelButtonClassName}
        onClick={() =>
          onChange(startOfDay(subDays(new Date(), 6)), startOfDay(new Date()))
        }
      >
        Last 7 days
      </button>
      <button
        data-selected={isLast30Days(startDate, endDate)}
        className={leftPanelButtonClassName}
        onClick={() =>
          onChange(startOfDay(subDays(new Date(), 29)), startOfDay(new Date()))
        }
      >
        Last 30 days
      </button>
      <button
        data-selected={isThisQuarter(startDate, endDate)}
        className={leftPanelButtonClassName}
        onClick={() => {
          const now = new Date();
          onChange(startOfQuarter(now), endOfQuarter(now));
        }}
      >
        This quarter
      </button>
      <button
        data-selected={isLastQuarter(startDate, endDate)}
        className={leftPanelButtonClassName}
        onClick={() => {
          const now = new Date();
          const lastQuarter = subQuarters(now, 1);
          onChange(startOfQuarter(lastQuarter), endOfQuarter(lastQuarter));
        }}
      >
        Last quarter
      </button>
      <button
        data-selected={isThisYear(startDate, endDate)}
        className={leftPanelButtonClassName}
        onClick={() => {
          const now = new Date();
          onChange(startOfDay(startOfYear(now)), startOfDay(endOfYear(now)));
        }}
      >
        This year
      </button>
      <button
        data-selected={isLastYear(startDate, endDate)}
        className={leftPanelButtonClassName}
        onClick={() => {
          const now = new Date();
          const lastYear = subYears(now, 1);
          onChange(
            startOfDay(startOfYear(lastYear)),
            startOfDay(endOfYear(lastYear))
          );
        }}
      >
        Last year
      </button>
      <button
        className={leftPanelButtonClassName}
        data-selected={isHypotheticalAllTime(startDate, endDate)}
        onClick={() => {
          onChange(new Date(0), addYears(new Date(), 1000));
        }}
      >
        All time
      </button>
    </div>
  );
}
