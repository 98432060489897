import { forwardRef } from 'react';
import { cn } from './ui.helpers';

const TableHead = forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={cn(
      'h-12 text-nowrap px-4 text-left align-middle text-sm font-semibold leading-tight text-zinc-800 [&:has([role=checkbox])]:pr-0',
      className
    )}
    {...props}
  />
));
TableHead.displayName = 'TableHead';

export default TableHead;
