import { cn } from './ui.helpers';

interface PageHeaderProps {
  children?: React.ReactNode;
  className?: string;
  headerRight?: React.ReactNode;
  title: string;
}

export default function PageHeader({
  children,
  className,
  headerRight,
  title,
}: PageHeaderProps) {
  return (
    <header className={cn('sticky top-0 shadow-sm', className)}>
      <div className="flex h-14 items-center justify-between border-b border-s-zinc-200 bg-white px-10">
        <h1 className="bg-white font-semibold text-zinc-900">{title}</h1>
        {headerRight}
      </div>
      {children}
    </header>
  );
}
