import { useEffect, useRef } from 'react';

export function useScrollIntoView<T>(
  dep: T
): React.MutableRefObject<HTMLDivElement> {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }, [dep]);
  return ref as React.MutableRefObject<HTMLDivElement>;
}
