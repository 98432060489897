import { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';

import { ModalLayout } from '@/shared-ui';
import { useOnboardingCompanyInfo } from './manager-onboarding-company-info.hooks';
import { useOnboarding } from './manager-onboarding.hooks';

const ManagerOnboardingEvoLoading = lazy(
  () => import('./manager-onboarding-evo-loading')
);

export default function OnboardingCompanyInfoSubmitting() {
  const { isSubmitting, error: addCompanyError } = useOnboardingCompanyInfo();
  const { isLoading: isCompanyInfoLoading, companyInfo } =
    useOnboarding(isSubmitting);

  if (addCompanyError) {
    return <Navigate to="/home/onboarding/company-info" />;
  }

  if ((!isCompanyInfoLoading && companyInfo) || !isSubmitting) {
    return <Navigate to="/home/onboarding/questions" />;
  }

  return (
    <ModalLayout hideHeader hideSubHeader>
      <div className="flex h-[90vh] items-center justify-center">
        <Suspense fallback={null}>
          <ManagerOnboardingEvoLoading />
        </Suspense>
      </div>
    </ModalLayout>
  );
}
