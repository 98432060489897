import { gql } from '../__generated__/gql';

export const V2_ADD_INSIGHT = gql(/* GraphQL */ `
  mutation V2AddInsight($input: AddInsightV2Input!) {
    addInsightV2(input: $input) {
      id
      messages {
        id
        content
        createdAt
        sender
        metadata {
          isConversationOver
          multipleSelect
          options {
            label
            value
          }
          required
          type
        }
      }
      contact {
        id
        firstName
        lastName
        email
        phone
      }
      progress
    }
  }
`);
